import React from "react";
import { Link } from "react-router-dom";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-page">
      <div className="banner">
        <img
          src="/images/header/banner.png"
          alt="Banner"
          className="banner-image"
        />
      </div>

      <div className="terms-page-content">
        <Link to="/">
          <img src="/images/logo/logo.svg" alt="Logo" className="logo" />
        </Link>

        <div className="terms-container">
          <header>Terms and Conditions</header>
          <section>
            <p>
              <strong>AGREEMENT TO OUR LEGAL TERMS</strong>
              <p>
                We are Managerize (&quot;<strong>Company</strong>,&quot; &quot;
                <strong>we</strong>,&quot; &quot;<strong>us</strong>,&quot;
                &quot;
                <strong>our</strong>&quot;) a company registered in Wyoming,
                United States.
              </p>
              <p>
                We operate the website{" "}
                <a href="https://managerize.com/">https://managerize.com</a>{" "}
                (the &quot;<strong>Site</strong>&quot;), the mobile application{" "}
                <strong>Managerize: Workforce</strong> (the &quot;
                <strong>App</strong>&quot;), as well as any other related
                products and services that refer or link to these legal terms
                (the &quot;
                <strong>Legal Terms</strong>&quot;) (collectively, the &quot;
                <strong>Services</strong>&quot;).
              </p>
              <p>
                <strong>Managerize</strong> is an employee management platform
                designed to streamline workforce operations. It enables
                employers to track employee data, manage clock-ins and
                clock-outs, process payments, and oversee work schedules. By
                combining robust tools with secure data handling, Managerize
                simplifies employee management and enhances organizational
                efficiency.
              </p>
              <p>
                You can contact us by email at{" "}
                <a href="mailto:contact@managerize.com">
                  contact@managerize.com
                </a>
                .
              </p>

              <p>
                These Legal Terms constitute a legally binding agreement made
                between you, whether personally or on behalf of an entity
                (&quot;
                <strong>you</strong>&quot;), and Managerize, concerning your
                access to and use of the Services. You agree that by accessing
                the Services, you have read, understood, and agreed to be bound
                by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF
                THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
                THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
              </p>

              <p>
                Supplemental terms and conditions or documents that may be
                posted on the Services from time to time are hereby expressly
                incorporated herein by reference. We reserve the right, in our
                sole discretion, to make changes or modifications to these Legal
                Terms from time to time. We will alert you about any changes by
                updating the &quot;Last updated&quot; date of these Legal Terms,
                and you waive any right to receive specific notice of each such
                change. It is your responsibility to periodically review these
                Legal Terms to stay informed of updates. You will be subject to,
                and will be deemed to have been made aware of and to have
                accepted, the changes in any revised Legal Terms by your
                continued use of the Services after the date such revised Legal
                Terms are posted.
              </p>

              <p>
                <strong>
                  The Services are intended for users who are at least 18 years
                  old. Persons under the age of 18 are not permitted to use or
                  register for the Services.
                </strong>
              </p>

              <p>
                We recommend that you print a copy of these Legal Terms for your
                records.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>1. OUR SERVICES</strong>
              <p>
                The information provided when using the Services is not intended
                for distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any
                registration requirement within such jurisdiction or country.
                Accordingly, those persons who choose to access the Services
                from other locations do so on their own initiative and are
                solely responsible for compliance with local laws, if and to the
                extent local laws are applicable.
              </p>

              <p>
                The Services are not tailored to comply with industry-specific
                regulations (Health Insurance Portability and Accountability Act
                (HIPAA), Federal Information Security Management Act (FISMA),
                etc.), so if your interactions would be subjected to such laws,
                you may not use the Services. You may not use the Services in a
                way that would violate the Gramm-Leach-Bliley Act (GLBA).
              </p>

              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>2. INTELLECTUAL PROPERTY RIGHTS</strong>
              <p>
                <strong>Our intellectual property</strong>
              </p>
              <p>
                We are the owner or the licensee of all intellectual property
                rights in our Services, including all source code, databases,
                functionality, software, website designs, audio, video, text,
                photographs, and graphics in the Services (collectively, the
                &quot;Content&quot;), as well as the trademarks, service marks,
                and logos contained therein (the &quot;Marks&quot;).
              </p>

              <p>
                Our Content and Marks are protected by copyright and trademark
                laws (and various other intellectual property rights and unfair
                competition laws) and treaties in the United States and around
                the world.
              </p>

              <p>
                The Content and Marks are provided in or through the Services
                &quot;AS IS&quot; for your internal business purpose only.
              </p>
              <p>
                <strong>Your use of our Services</strong>
              </p>
              <p>
                Subject to your compliance with these Legal Terms, including the
                PROHIBITED ACTIVITIES section below, we grant you a
                non-exclusive, non-transferable, revocable license to:
              </p>
              <ul>
                <li>access the Services; and</li>
                <li>
                  download or print a copy of any portion of the Content to
                  which you have properly gained access,
                </li>
              </ul>
              <p>solely for your internal business purpose.</p>

              <p>
                Except as set out in this section or elsewhere in our Legal
                Terms, no part of the Services and no Content or Marks may be
                copied, reproduced, aggregated, republished, uploaded, posted,
                publicly displayed, encoded, translated, transmitted,
                distributed, sold, licensed, or otherwise exploited for any
                commercial purpose whatsoever, without our express prior written
                permission.
              </p>

              <p>
                If you wish to make any use of the Services, Content, or Marks
                other than as set out in this section or elsewhere in our Legal
                Terms, please address your request to:{" "}
                <a href="mailto:contact@managerize.com">
                  contact@managerize.com
                </a>
                . If we ever grant you the permission to post, reproduce, or
                publicly display any part of our Services or Content, you must
                identify us as the owners or licensors of the Services, Content,
                or Marks and ensure that any copyright or proprietary notice
                appears or is visible on posting, reproducing, or displaying our
                Content.
              </p>

              <p>
                We reserve all rights not expressly granted to you in and to the
                Services, Content, and Marks.
              </p>

              <p>
                Any breach of these Intellectual Property Rights will constitute
                a material breach of our Legal Terms and your right to use our
                Services will terminate immediately.
              </p>
              <p>
                <strong>Your submissions and contributions</strong>
              </p>
              <p>
                Please review this section and the PROHIBITED ACTIVITIES section
                carefully prior to using our Services to understand the (a)
                rights you give us and (b) obligations you have when you post or
                upload any content through the Services.
              </p>

              <p>
                <strong>Submissions:</strong> By directly sending us any
                question, comment, suggestion, idea, feedback, or other
                information about the Services (&quot;Submissions&quot;), you
                agree to assign to us all intellectual property rights in such
                Submission. You agree that we shall own this Submission and be
                entitled to its unrestricted use and dissemination for any
                lawful purpose, commercial or otherwise, without acknowledgment
                or compensation to you.
              </p>

              <p>
                <strong>Contributions:</strong> The Services may invite you to
                chat, contribute to, or participate in blogs, message boards,
                online forums, and other functionality during which you may
                create, submit, post, display, transmit, publish, distribute, or
                broadcast content and materials to us or through the Services,
                including but not limited to text, writings, video, audio,
                photographs, music, graphics, comments, reviews, rating
                suggestions, personal information, or other material
                (&quot;Contributions&quot;). Any Submission that is publicly
                posted shall also be treated as a Contribution.
              </p>

              <p>
                You understand that Contributions may be viewable by other users
                of the Services and possibly through third-party websites.
              </p>

              <p>
                <strong>
                  When you post Contributions, you grant us a license (including
                  use of your name, trademarks, and logos):{" "}
                </strong>
                By posting any Contributions, you grant us an unrestricted,
                unlimited, irrevocable, perpetual, non-exclusive, transferable,
                royalty-free, fully-paid, worldwide right, and license to: use,
                copy, reproduce, distribute, sell, resell, publish, broadcast,
                retitle, store, publicly perform, publicly display, reformat,
                translate, excerpt (in whole or in part), and exploit your
                Contributions (including, without limitation, your image, name,
                and voice) for any purpose, commercial, advertising, or
                otherwise, to prepare derivative works of, or incorporate into
                other works, your Contributions, and to sublicense the licenses
                granted in this section. Our use and distribution may occur in
                any media formats and through any media channels.
              </p>

              <p>
                This license includes our use of your name, company name, and
                franchise name, as applicable, and any of the trademarks,
                service marks, trade names, logos, and personal and commercial
                images you provide.
              </p>

              <p>
                <strong>
                  You are responsible for what you post or upload:
                </strong>{" "}
                By sending us Submissions and/or posting Contributions through
                any part of the Services or making Contributions accessible
                through the Services by linking your account through the
                Services to any of your social networking accounts, you:
              </p>
              <ul>
                <li>
                  confirm that you have read and agree with our PROHIBITED
                  ACTIVITIES and will not post, send, publish, upload, or
                  transmit through the Services any Submission nor post any
                  Contribution that is illegal, harassing, hateful, harmful,
                  defamatory, obscene, bullying, abusive, discriminatory,
                  threatening to any person or group, sexually explicit, false,
                  inaccurate, deceitful, or misleading;
                </li>
                <li>
                  to the extent permissible by applicable law, waive any and all
                  moral rights to any such Submission and/or Contribution;
                </li>
                <li>
                  warrant that any such Submission and/or Contributions are
                  original to you or that you have the necessary rights and
                  licenses to submit such Submissions and/or Contributions and
                  that you have full authority to grant us the above-mentioned
                  rights in relation to your Submissions and/or Contributions;
                  and
                </li>
                <li>
                  warrant and represent that your Submissions and/or
                  Contributions do not constitute confidential information.
                </li>
              </ul>
              <p>
                You are solely responsible for your Submissions and/or
                Contributions and you expressly agree to reimburse us for any
                and all losses that we may suffer because of your breach of (a)
                this section, (b) any third party&rsquo;s intellectual property
                rights, or (c) applicable law.
              </p>

              <p>
                <strong>We may remove or edit your Content:</strong> Although we
                have no obligation to monitor any Contributions, we shall have
                the right to remove or edit any Contributions at any time
                without notice if in our reasonable opinion we consider such
                Contributions harmful or in breach of these Legal Terms. If we
                remove or edit any such Contributions, we may also suspend or
                disable your account and report you to the authorities.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>3. USER REPRESENTATIONS</strong>
              <p>
                By using the Services, you represent and warrant that: (1) all
                registration information you submit will be true, accurate,
                current, and complete; (2) you will maintain the accuracy of
                such information and promptly update such registration
                information as necessary; (3) you have the legal capacity and
                you agree to comply with these Legal Terms; (4) you are not a
                minor in the jurisdiction in which you reside; (5) you will not
                access the Services through automated or non-human means,
                whether through a bot, script or otherwise; (6) you will not use
                the Services for any illegal or unauthorized purpose; and (7)
                your use of the Services will not violate any applicable law or
                regulation.
              </p>

              <p>
                If you provide any information that is untrue, inaccurate, not
                current, or incomplete, we have the right to suspend or
                terminate your account and refuse any and all current or future
                use of the Services (or any portion thereof).
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>4. USER REGISTRATION</strong>
              <p>
                You may be required to register to use the Services. You agree
                to keep your password confidential and will be responsible for
                all use of your account and password. We reserve the right to
                remove, reclaim, or change a username you select if we
                determine, in our sole discretion, that such username is
                inappropriate, obscene, or otherwise objectionable.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>5. PURCHASES AND PAYMENT</strong>
              <p>
                We use <strong>Stripe</strong> as our secure payment processor
                to facilitate all transactions on the Managerize platform.
              </p>
              <p>
                <strong>Accurate Account Information</strong>
              </p>
              <ul>
                <li>
                  You agree to provide current, complete, and accurate payment
                  and account details for all purchases made through our
                  Services.
                </li>
                <li>
                  It is your responsibility to promptly update your payment
                  information, including your email address, payment method, and
                  payment card expiration date, to ensure uninterrupted service
                  and proper communication.
                </li>
                <li>
                  Applicable sales tax will be added to the total price of your
                  purchase as required by law. Prices may change at our
                  discretion without prior notice.
                </li>
              </ul>
              <p>
                <strong>Authorization and Charges</strong>
              </p>
              <ul>
                <li>
                  You agree to pay all fees and charges at the prices in effect
                  at the time of purchase.
                </li>
                <li>
                  By completing a purchase, you authorize us to charge your
                  selected payment method for the full amount, including
                  applicable taxes.
                </li>
                <li>
                  We reserve the right to correct pricing errors or mistakes,
                  even if the payment has already been processed.
                </li>
              </ul>
              <p>
                <strong>Order Limitations and Cancellations</strong>
              </p>
              <ul>
                <li>
                  We reserve the right to refuse or cancel any order made
                  through our platform.
                </li>
                <li>
                  At our discretion, we may impose limitations or cancel
                  transactions based on:
                  <ul>
                    <li>
                      The number of accounts purchased under the same customer
                      account.
                    </li>
                    <li>Orders using the same payment method.</li>
                    <li>Purchases associated with the same billing address.</li>
                  </ul>
                </li>
                <li>
                  We also reserve the right to limit or prohibit transactions
                  that, in our judgment, appear to be placed by resellers,
                  distributors, or any other entity attempting to misuse the
                  platform.
                </li>
              </ul>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>6. SUBSCRIPTIONS</strong>
              <p>
                <strong>Billing and Renewal</strong>
              </p>
              <p>
                Your subscription will continue and automatically renew unless
                canceled. You consent to our charging your payment method on a
                recurring basis without requiring your prior approval for each
                recurring charge, until such time as you cancel the applicable
                order. The length of your billing cycle will depend on the type
                of subscription plan you choose when you subscribed to the
                Services.
              </p>
              <p>
                <strong>Cancellation</strong>
              </p>
              <p>
                You can cancel your subscription at any time by logging into
                your account. Your cancellation will take effect at the end of
                the current paid term. If you have any questions or are
                unsatisfied with our Services, please email us at{" "}
                <a href="mailto:contact@managerize.com">
                  contact@managerize.com
                </a>
                .
              </p>
              <p>
                <strong>
                  Refund Policy (All subscription fees are non-refundable)
                  <br />{" "}
                </strong>
                Once a payment has been processed for the current billing cycle,
                no refunds or credits will be issued, including for partially
                used subscription periods. If you cancel your subscription, you
                will retain access to the Services until the end of the current
                paid term.
              </p>
              <p>
                We are committed to addressing any concerns or issues you may
                encounter with our Services and encourage you to reach out to{" "}
                <a href="mailto:contact@managerize.com">
                  contact@managerize.com
                </a>{" "}
                for support.
              </p>
              <p>
                <strong>Fee Changes</strong>
              </p>
              <p>
                We may, from time to time, make changes to the subscription fee
                and will communicate any price changes to you in accordance with
                applicable law.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>7. PROHIBITED ACTIVITIES AND OBLIGATIONS</strong>
              <p>
                The Services provided by Managerize are intended solely for
                employer and employee management purposes as outlined in our
                platform&apos;s features. Any use beyond these purposes is
                strictly prohibited. By accessing or using the Services, you
                agree to comply with these restrictions and your respective
                obligations as an employer or employee.
              </p>
              <p>
                <strong>Prohibited Activities</strong>
              </p>
              <p>
                You may not access or use the Services for purposes other than
                those explicitly provided by Managerize. Specifically, you agree
                not to:
              </p>
              <ol>
                <li>
                  <strong>Data Misuse</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Systematically retrieve data or content from the Services to
                  create a collection, compilation, database, or directory
                  without prior written consent.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Fraudulent Activities</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Trick, defraud, or mislead Managerize or its users, especially
                  in attempts to obtain sensitive account information, such as
                  passwords.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Security Breaches</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Circumvent, disable, or interfere with security features,
                  including those that prevent unauthorized access or copying of
                  content.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Reputation Damage</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Disparage, tarnish, or harm Managerize, its Services, or its
                  users in any way.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Harassment or Harm</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Use information obtained through the Services to harass,
                  abuse, or harm others.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Improper Use of Support</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Submit false reports or misuse customer support services.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Legal Violations</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Use the Services in ways that violate applicable laws or
                  regulations.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Unauthorized Linking or Framing</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Frame or link to the Services without prior authorization.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Malware and Spamming</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Upload or transmit viruses, Trojan horses, spyware, or spam
                  content that disrupts the functionality or enjoyment of the
                  Services.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Automated System Misuse</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Use scripts, bots, or automated tools for data mining, sending
                  messages, or other activities without authorization.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Copyright Infringement</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Delete or alter proprietary notices, such as copyrights, from
                  any content.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Impersonation</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Impersonate another user or use someone else&rsquo;s username
                  or account.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Data Collection Tools</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Upload passive or active data collection mechanisms, such as
                  spyware or cookies, without consent.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Service Disruption</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Interfere with or create an undue burden on the platform or
                  its connected networks.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Software Manipulation</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Copy, adapt, decipher, decompile, disassemble, or
                  reverse-engineer any software associated with the Services.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Unauthorized Automation</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Develop or use unauthorized automated systems such as
                  scrapers, spiders, or bots.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Unauthorized Commerce</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Use the Services for revenue-generating endeavors or
                  commercial enterprises without our endorsement.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Profile Misuse</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Sell, transfer, or share your profile without permission.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Unsolicited Communications</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Collect email addresses or user data to send unsolicited
                  messages or create accounts under false pretenses.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Advertising</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Use the platform to advertise or sell goods and services
                  without authorization.
                </li>
              </ul>
              <p>
                <strong>
                  <u>Employer Obligations</u>
                </strong>
              </p>
              <p>As an employer using Managerize, you are responsible for:</p>
              <ol>
                <li>
                  <strong>Employee Consent:</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Ensuring that you have obtained explicit consent from
                  employees to input their personal details, including names,
                  contact information, and job-related data.
                </li>
                <li>
                  Securing additional consent for accessing sensitive features
                  such as location tracking and camera usage through the app.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Accurate Data Entry:</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Providing current, accurate, and lawful information for
                  employees when creating accounts or managing their data.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Data Protection:</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Safeguarding employee data against unauthorized access,
                  misuse, or disclosure.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Compliance:</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Using the platform in accordance with applicable labor laws
                  and regulations.
                </li>
              </ul>
              <p>
                <strong>Employee Obligations</strong>
              </p>
              <p>
                As an employee accessing Managerize through employer-assigned
                credentials, you are responsible for:
              </p>
              <ol>
                <li>
                  <strong>Account Security:</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Keeping your assigned credentials and PIN secure and not
                  sharing them with others.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>App Usage:</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Using the platform only for the purposes authorized by your
                  employer, such as clocking in/out or viewing schedules.
                </li>
              </ul>
              <ol>
                <li>
                  <strong>Compliance:</strong>
                </li>
              </ol>
              <ul>
                <li>
                  Adhering to workplace policies and refraining from using the
                  platform for unauthorized activities.
                </li>
              </ul>
              <p>
                Violations of these terms may result in suspension or
                termination of your access to the Services and, if applicable,
                legal action.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>8. USER GENERATED CONTRIBUTIONS</strong>
              <p>
                The Services may invite you to chat, contribute to, or
                participate in blogs, message boards, online forums, and other
                functionality, and may provide you with the opportunity to
                create, submit, post, display, transmit, perform, publish,
                distribute, or broadcast content and materials to us or on the
                Services, including but not limited to text, writings, video,
                audio, photographs, graphics, comments, suggestions, or personal
                information or other material (collectively,
                &quot;Contributions&quot;). Contributions may be viewable by
                other users of the Services and through third-party websites. As
                such, any Contributions you transmit may be treated as
                non-confidential and non-proprietary. When you create or make
                available any Contributions, you thereby represent and warrant
                that:
              </p>
              <ul>
                <li>
                  The creation, distribution, transmission, public display, or
                  performance, and the accessing, downloading, or copying of
                  your Contributions do not and will not infringe the
                  proprietary rights, including but not limited to the
                  copyright, patent, trademark, trade secret, or moral rights of
                  any third party.
                </li>
                <li>
                  You are the creator and owner of or have the necessary
                  licenses, rights, consents, releases, and permissions to use
                  and to authorize us, the Services, and other users of the
                  Services to use your Contributions in any manner contemplated
                  by the Services and these Legal Terms.
                </li>
                <li>
                  You have the written consent, release, and/or permission of
                  each and every identifiable individual person in your
                  Contributions to use the name or likeness of each and every
                  such identifiable individual person to enable inclusion and
                  use of your Contributions in any manner contemplated by the
                  Services and these Legal Terms.
                </li>
                <li>
                  Your Contributions are not false, inaccurate, or misleading.
                </li>
                <li>
                  Your Contributions are not unsolicited or unauthorized
                  advertising, promotional materials, pyramid schemes, chain
                  letters, spam, mass mailings, or other forms of solicitation.
                </li>
                <li>
                  Your Contributions are not obscene, lewd, lascivious, filthy,
                  violent, harassing, libelous, slanderous, or otherwise
                  objectionable (as determined by us).
                </li>
                <li>
                  Your Contributions do not ridicule, mock, disparage,
                  intimidate, or abuse anyone.
                </li>
                <li>
                  Your Contributions are not used to harass or threaten (in the
                  legal sense of those terms) any other person and to promote
                  violence against a specific person or class of people.
                </li>
                <li>
                  Your Contributions do not violate any applicable law,
                  regulation, or rule.
                </li>
                <li>
                  Your Contributions do not violate the privacy or publicity
                  rights of any third party.
                </li>
                <li>
                  Your Contributions do not violate any applicable law
                  concerning child pornography, or otherwise intended to protect
                  the health or well-being of minors.
                </li>
                <li>
                  Your Contributions do not include any offensive comments that
                  are connected to race, national origin, gender, sexual
                  preference, or physical handicap.
                </li>
                <li>
                  Your Contributions do not otherwise violate, or link to
                  material that violates, any provision of these Legal Terms, or
                  any applicable law or regulation.
                </li>
              </ul>
              <p>
                Any use of the Services in violation of the foregoing violates
                these Legal Terms and may result in, among other things,
                termination or suspension of your rights to use the Services.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>9. CONTRIBUTION LICENSE</strong>
              <p>
                By posting your Contributions to any part of the Services, you
                automatically grant, and you represent and warrant that you have
                the right to grant, to us an unrestricted, unlimited,
                irrevocable, perpetual, non-exclusive, transferable,
                royalty-free, fully-paid, worldwide right, and license to host,
                use, copy, reproduce, disclose, sell, resell, publish,
                broadcast, retitle, archive, store, cache, publicly perform,
                publicly display, reformat, translate, transmit, excerpt (in
                whole or in part), and distribute such Contributions (including,
                without limitation, your image and voice) for any purpose,
                commercial, advertising, or otherwise, and to prepare derivative
                works of, or incorporate into other works, such Contributions,
                and grant and authorize sublicenses of the foregoing. The use
                and distribution may occur in any media formats and through any
                media channels.
              </p>

              <p>
                This license will apply to any form, media, or technology now
                known or hereafter developed, and includes our use of your name,
                company name, and franchise name, as applicable, and any of the
                trademarks, service marks, trade names, logos, and personal and
                commercial images you provide. You waive all moral rights in
                your Contributions, and you warrant that moral rights have not
                otherwise been asserted in your Contributions.
              </p>

              <p>
                We do not assert any ownership over your Contributions. You
                retain full ownership of all of your Contributions and any
                intellectual property rights or other proprietary rights
                associated with your Contributions. We are not liable for any
                statements or representations in your Contributions provided by
                you in any area on the Services. You are solely responsible for
                your Contributions to the Services and you expressly agree to
                exonerate us from any and all responsibility and to refrain from
                any legal action against us regarding your Contributions.
              </p>

              <p>
                We have the right, in our sole and absolute discretion, (1) to
                edit, redact, or otherwise change any Contributions; (2) to
                re-categorize any Contributions to place them in more
                appropriate locations on the Services; and (3) to pre-screen or
                delete any Contributions at any time and for any reason, without
                notice. We have no obligation to monitor your Contributions.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>10. MOBILE APPLICATION LICENSE</strong>
              <p>
                <strong>Use License</strong>
              </p>
              <p>
                If you access the Services via the App, then we grant you a
                revocable, non-exclusive, non-transferable, limited right to
                install and use the App on wireless electronic devices owned or
                controlled by you, and to access and use the App on such devices
                strictly in accordance with the terms and conditions of this
                mobile application license contained in these Legal Terms. You
                shall not: (1) except as permitted by applicable law, decompile,
                reverse engineer, disassemble, attempt to derive the source code
                of, or decrypt the App; (2) make any modification, adaptation,
                improvement, enhancement, translation, or derivative work from
                the App; (3) violate any applicable laws, rules, or regulations
                in connection with your access or use of the App; (4) remove,
                alter, or obscure any proprietary notice (including any notice
                of copyright or trademark) posted by us or the licensors of the
                App; (5) use the App for any revenue-generating endeavor,
                commercial enterprise, or other purpose for which it is not
                designed or intended; (6) make the App available over a network
                or other environment permitting access or use by multiple
                devices or users at the same time; (7) use the App for creating
                a product, service, or software that is, directly or indirectly,
                competitive with or in any way a substitute for the App; (8) use
                the App to send automated queries to any website or to send any
                unsolicited commercial email; or (9) use any proprietary
                information or any of our interfaces or our other intellectual
                property in the design, development, manufacture, licensing, or
                distribution of any applications, accessories, or devices for
                use with the App.
              </p>
              <p>
                <strong>Apple and Android Devices</strong>
              </p>
              <p>
                The following terms apply when you use the App obtained from
                either the Apple Store or Google Play (each an &quot;App
                Distributor&quot;) to access the Services: (1) the license
                granted to you for our App is limited to a non-transferable
                license to use the application on a device that utilizes the
                Apple iOS or Android operating systems, as applicable, and in
                accordance with the usage rules set forth in the applicable App
                Distributor&rsquo;s terms of service; (2) we are responsible for
                providing any maintenance and support services with respect to
                the App as specified in the terms and conditions of this mobile
                application license contained in these Legal Terms or as
                otherwise required under applicable law, and you acknowledge
                that each App Distributor has no obligation whatsoever to
                furnish any maintenance and support services with respect to the
                App; (3) in the event of any failure of the App to conform to
                any applicable warranty, you may notify the applicable App
                Distributor, and the App Distributor, in accordance with its
                terms and policies, may refund the purchase price, if any, paid
                for the App, and to the maximum extent permitted by applicable
                law, the App Distributor will have no other warranty obligation
                whatsoever with respect to the App; (4) you represent and
                warrant that (i) you are not located in a country that is
                subject to a US government embargo, or that has been designated
                by the US government as a &quot;terrorist supporting&quot;
                country and (ii) you are not listed on any US government list of
                prohibited or restricted parties; (5) you must comply with
                applicable third-party terms of agreement when using the App,
                e.g., if you have a VoIP application, then you must not be in
                violation of their wireless data service agreement when using
                the App; and (6) you acknowledge and agree that the App
                Distributors are third-party beneficiaries of the terms and
                conditions in this mobile application license contained in these
                Legal Terms, and that each App Distributor will have the right
                (and will be deemed to have accepted the right) to enforce the
                terms and conditions in this mobile application license
                contained in these Legal Terms against you as a third-party
                beneficiary thereof.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>11. THIRD-PARTY WEBSITES AND CONTENT</strong>
              <p>
                The Services may contain (or you may be sent via the Site or
                App) links to other websites (&quot;Third-Party Websites&quot;)
                as well as articles, photographs, text, graphics, pictures,
                designs, music, sound, video, information, applications,
                software, and other content or items belonging to or originating
                from third parties (&quot;Third-Party Content&quot;). Such
                Third-Party Websites and Third-Party Content are not
                investigated, monitored, or checked for accuracy,
                appropriateness, or completeness by us, and we are not
                responsible for any Third-Party Websites accessed through the
                Services or any Third-Party Content posted on, available
                through, or installed from the Services, including the content,
                accuracy, offensiveness, opinions, reliability, privacy
                practices, or other policies of or contained in the Third-Party
                Websites or the Third-Party Content. Inclusion of, linking to,
                or permitting the use or installation of any Third-Party
                Websites or any Third-Party Content does not imply approval or
                endorsement thereof by us. If you decide to leave the Services
                and access the Third-Party Websites or to use or install any
                Third-Party Content, you do so at your own risk, and you should
                be aware these Legal Terms no longer govern. You should review
                the applicable terms and policies, including privacy and data
                gathering practices, of any website to which you navigate from
                the Services or relating to any applications you use or install
                from the Services. Any purchases you make through Third-Party
                Websites will be through other websites and from other
                companies, and we take no responsibility whatsoever in relation
                to such purchases which are exclusively between you and the
                applicable third party. You agree and acknowledge that we do not
                endorse the products or services offered on Third-Party Websites
                and you shall hold us blameless from any harm caused by your
                purchase of such products or services. Additionally, you shall
                hold us blameless from any losses sustained by you or harm
                caused to you relating to or resulting in any way from any
                Third-Party Content or any contact with Third-Party Websites.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>12. SERVICES MANAGEMENT</strong>
              <p>
                We reserve the right, but not the obligation, to: (1) monitor
                the Services for violations of these Legal Terms; (2) take
                appropriate legal action against anyone who, in our sole
                discretion, violates the law or these Legal Terms, including
                without limitation, reporting such user to law enforcement
                authorities; (3) in our sole discretion and without limitation,
                refuse, restrict access to, limit the availability of, or
                disable (to the extent technologically feasible) any of your
                Contributions or any portion thereof; (4) in our sole discretion
                and without limitation, notice, or liability, to remove from the
                Services or otherwise disable all files and content that are
                excessive in size or are in any way burdensome to our systems;
                and (5) otherwise manage the Services in a manner designed to
                protect our rights and property and to facilitate the proper
                functioning of the Services.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>13. PRIVACY POLICY</strong>
              <p>
                We care about data privacy and security. Please review our
                Privacy Policy:{" "}
                <a href="https://managerize.com/privacy-policy">
                  https://managerize.com/privacy-policy
                </a>
                . By using the Services, you agree to be bound by our Privacy
                Policy, which is incorporated into these Legal Terms. Please be
                advised the Services are hosted in the United Kingdom. If you
                access the Services from any other region of the world with laws
                or other requirements governing personal data collection, use,
                or disclosure that differ from applicable laws in the United
                Kingdom, then through your continued use of the Services, you
                are transferring your data to the United Kingdom, and you
                expressly consent to have your data transferred to and processed
                in the United Kingdom.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>14. TERM AND TERMINATION</strong>
              <p>
                These Legal Terms shall remain in full force and effect while
                you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF
                THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE
                DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND
                USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES),
                TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT
                LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
                COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW
                OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE
                SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION
                THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
                DISCRETION.
              </p>

              <p>
                If we terminate or suspend your account for any reason, you are
                prohibited from registering and creating a new account under
                your name, a fake or borrowed name, or the name of any third
                party, even if you may be acting on behalf of the third party.
                In addition to terminating or suspending your account, we
                reserve the right to take appropriate legal action, including
                without limitation pursuing civil, criminal, and injunctive
                redress.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>15. MODIFICATIONS AND INTERRUPTIONS</strong>
              <p>
                We reserve the right to change, modify, or remove the contents
                of the Services at any time or for any reason at our sole
                discretion without notice. However, we have no obligation to
                update any information on our Services. We will not be liable to
                you or any third party for any modification, price change,
                suspension, or discontinuance of the Services.
              </p>

              <p>
                We cannot guarantee the Services will be available at all times.
                We may experience hardware, software, or other problems or need
                to perform maintenance related to the Services, resulting in
                interruptions, delays, or errors. We reserve the right to
                change, revise, update, suspend, discontinue, or otherwise
                modify the Services at any time or for any reason without notice
                to you. You agree that we have no liability whatsoever for any
                loss, damage, or inconvenience caused by your inability to
                access or use the Services during any downtime or discontinuance
                of the Services. Nothing in these Legal Terms will be construed
                to obligate us to maintain and support the Services or to supply
                any corrections, updates, or releases in connection therewith.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>16. GOVERNING LAW</strong>
              <p>
                These Legal Terms and your use of the Services are governed by
                and construed in accordance with the laws of the State of
                Wyoming applicable to agreements made and to be entirely
                performed within the State of Wyoming, without regard to its
                conflict of law principles.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>17. DISPUTE RESOLUTION</strong>
              <p>
                <strong>Informal Negotiations</strong>
              </p>
              <p>
                To expedite resolution and control the cost of any dispute,
                controversy, or claim related to these Legal Terms (each a
                &quot;Dispute&quot; and collectively, the &quot;Disputes&quot;)
                brought by either you or us (individually, a &quot;Party&quot;
                and collectively, the &quot;Parties&quot;), the Parties agree to
                first attempt to negotiate any Dispute (except those Disputes
                expressly provided below) informally for at least thirty (30)
                days before initiating arbitration. Such informal negotiations
                commence upon written notice from one Party to the other Party.
              </p>
              <p>
                <strong>Binding Arbitration</strong>
              </p>
              <p>
                If the Parties are unable to resolve a Dispute through informal
                negotiations, the Dispute (except those Disputes expressly
                excluded below) will be finally and exclusively resolved by
                binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION,
                YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL.
                The arbitration shall be commenced and conducted under the
                Commercial Arbitration Rules of the American Arbitration
                Association (&quot;AAA&quot;) and, where appropriate, the
                AAA&rsquo;s Supplementary Procedures for Consumer Related
                Disputes (&quot;AAA Consumer Rules&quot;), both of which are
                available at the{" "}
                <a href="http://www.adr.org/">
                  American Arbitration Association (AAA) website
                </a>
                . Your arbitration fees and your share of arbitrator
                compensation shall be governed by the AAA Consumer Rules and,
                where appropriate, limited by the AAA Consumer Rules. The
                arbitration may be conducted in person, through the submission
                of documents, by phone, or online. The arbitrator will make a
                decision in writing, but need not provide a statement of reasons
                unless requested by either Party. The arbitrator must follow
                applicable law, and any award may be challenged if the
                arbitrator fails to do so. Except where otherwise required by
                the applicable AAA rules or applicable law, the arbitration will
                take place in Wyoming. Except as otherwise provided herein, the
                Parties may litigate in court to compel arbitration, stay
                proceedings pending arbitration, or to confirm, modify, vacate,
                or enter judgment on the award entered by the arbitrator.
              </p>

              <p>
                If for any reason, a Dispute proceeds in court rather than
                arbitration, the Dispute shall be commenced or prosecuted in the
                state and federal courts located in Wyoming, and the Parties
                hereby consent to, and waive all defenses of lack of personal
                jurisdiction, and forum non conveniens with respect to venue and
                jurisdiction in such state and federal courts. Application of
                the United Nations Convention on Contracts for the International
                Sale of Goods and the Uniform Computer Information Transaction
                Act (UCITA) are excluded from these Legal Terms.
              </p>

              <p>
                In no event shall any Dispute brought by either Party related in
                any way to the Services be commenced more than one (1) year
                after the cause of action arose. If this provision is found to
                be illegal or unenforceable, then neither Party will elect to
                arbitrate any Dispute falling within that portion of this
                provision found to be illegal or unenforceable and such Dispute
                shall be decided by a court of competent jurisdiction within the
                courts listed for jurisdiction above, and the Parties agree to
                submit to the personal jurisdiction of that court.
              </p>
              <p>
                <strong>Restrictions</strong>
              </p>
              <p>
                The Parties agree that any arbitration shall be limited to the
                Dispute between the Parties individually. To the full extent
                permitted by law, (a) no arbitration shall be joined with any
                other proceeding; (b) there is no right or authority for any
                Dispute to be arbitrated on a class-action basis or to utilize
                class action procedures; and (c) there is no right or authority
                for any Dispute to be brought in a purported representative
                capacity on behalf of the general public or any other persons.
              </p>
              <p>
                <strong>
                  Exceptions to Informal Negotiations and Arbitration
                </strong>
              </p>
              <p>
                The Parties agree that the following Disputes are not subject to
                the above provisions concerning informal negotiations binding
                arbitration: (a) any Disputes seeking to enforce or protect, or
                concerning the validity of, any of the intellectual property
                rights of a Party; (b) any Dispute related to, or arising from,
                allegations of theft, piracy, invasion of privacy, or
                unauthorized use; and (c) any claim for injunctive relief. If
                this provision is found to be illegal or unenforceable, then
                neither Party will elect to arbitrate any Dispute falling within
                that portion of this provision found to be illegal or
                unenforceable and such Dispute shall be decided by a court of
                competent jurisdiction within the courts listed for jurisdiction
                above, and the Parties agree to submit to the personal
                jurisdiction of that court.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>18. CORRECTIONS</strong>
              <p>
                There may be information on the Services that contains
                typographical errors, inaccuracies, or omissions, including
                descriptions, pricing, availability, and various other
                information. We reserve the right to correct any errors,
                inaccuracies, or omissions and to change or update the
                information on the Services at any time, without prior notice.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>19. DISCLAIMER</strong>
              <p>
                THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS.
                YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE
                RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
                WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES
                AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
                ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES&apos; CONTENT
                OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO
                THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY
                FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND
                MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
                SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
                SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
                INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
                TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES,
                TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
                THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS
                OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR
                DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
                CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE
                SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
                BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE,
                OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
                OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY
                BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
                ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
                PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
                ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
                CAUTION WHERE APPROPRIATE.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>20. LIMITATIONS OF LIABILITY</strong>
              <p>
                IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
                LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
                DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
                OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE
                HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
                LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE
                FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT
                PAID, IF ANY, BY YOU TO US DURING THE six (6) mONTH PERIOD PRIOR
                TO ANY CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND
                INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
                WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF
                THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR
                LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
                RIGHTS.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>21. INDEMNIFICATION</strong>
              <p>
                You agree to defend, indemnify, and hold us harmless, including
                our subsidiaries, affiliates, and all of our respective
                officers, agents, partners, and employees, from and against any
                loss, damage, liability, claim, or demand, including reasonable
                attorneys&rsquo; fees and expenses, made by any third party due
                to or arising out of: (1) your Contributions; (2) use of the
                Services; (3) breach of these Legal Terms; (4) any breach of
                your representations and warranties set forth in these Legal
                Terms; (5) your violation of the rights of a third party,
                including but not limited to intellectual property rights; or
                (6) any overt harmful act toward any other user of the Services
                with whom you connected via the Services. Notwithstanding the
                foregoing, we reserve the right, at your expense, to assume the
                exclusive defense and control of any matter for which you are
                required to indemnify us, and you agree to cooperate, at your
                expense, with our defense of such claims. We will use reasonable
                efforts to notify you of any such claim, action, or proceeding
                which is subject to this indemnification upon becoming aware of
                it.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>22. USER DATA</strong>
              <p>
                We will maintain certain data that you transmit to the Services
                for the purpose of managing the performance of the Services, as
                well as data relating to your use of the Services. Although we
                perform regular routine backups of data, you are solely
                responsible for all data that you transmit or that relates to
                any activity you have undertaken using the Services. You agree
                that we shall have no liability to you for any loss or
                corruption of any such data, and you hereby waive any right of
                action against us arising from any such loss or corruption of
                such data.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>
                23. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </strong>
              <p>
                Visiting the Services, sending us emails, and completing online
                forms constitute electronic communications. You consent to
                receive electronic communications, and you agree that all
                agreements, notices, disclosures, and other communications we
                provide to you electronically, via email and on the Services,
                satisfy any legal requirement that such communication be in
                writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY
                OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR
                COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights
                or requirements under any statutes, regulations, rules,
                ordinances, or other laws in any jurisdiction which require an
                original signature or delivery or retention of non-electronic
                records, or to payments or the granting of credits by any means
                other than electronic means.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>24. MISCELLANEOUS</strong>
              <p>
                These Legal Terms and any policies or operating rules posted by
                us on the Services or in respect to the Services constitute the
                entire agreement and understanding between you and us. Our
                failure to exercise or enforce any right or provision of these
                Legal Terms shall not operate as a waiver of such right or
                provision. These Legal Terms operate to the fullest extent
                permissible by law. We may assign any or all of our rights and
                obligations to others at any time. We shall not be responsible
                or liable for any loss, damage, delay, or failure to act caused
                by any cause beyond our reasonable control. If any provision or
                part of a provision of these Legal Terms is determined to be
                unlawful, void, or unenforceable, that provision or part of the
                provision is deemed severable from these Legal Terms and does
                not affect the validity and enforceability of any remaining
                provisions. There is no joint venture, partnership, employment
                or agency relationship created between you and us as a result of
                these Legal Terms or use of the Services. You agree that these
                Legal Terms will not be construed against us by virtue of having
                drafted them. You hereby waive any and all defenses you may have
                based on the electronic form of these Legal Terms and the lack
                of signing by the parties hereto to execute these Legal Terms.
              </p>
              <p>
                <strong>&nbsp;</strong>
              </p>
              <strong>25. CONTACT US</strong>
              <p>
                In order to resolve a complaint regarding the Services or to
                receive further information regarding the use of the Services,
                please contact us at:
              </p>

              <p>
                <strong>Managerize</strong>
              </p>
              <p>&mdash;----Address&mdash;----, WY</p>
              <p>United States</p>
              <p>
                <a href="mailto:contact@managerize.com">
                  contact@managerize.com
                </a>
              </p>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
