import React, { useEffect, useState } from "react";
import DashboardNavbar from "../../components/DashboardNavbar/DashboardNavbar";
import SettingsCompanyInfoCard from "../../components/SettingsCompanyInfoCard/SettingsCompanyInfoCard";
import SettingsUserInfoCard from "../../components/SettingsUserInfoCard/SettingsUserInfoCard";
import {
  getCompanySettings,
  getUserLimitedInfo,
} from "../../apiService/apiService";
import Loader from "../../components/Loader/Loader";
import { Company, UserLimitedInfo } from "../../constants/interfaces";
import "./Settings.css";

const Settings = () => {
  const [company, setCompany] = useState<Company | null>(null);
  const [user, setUser] = useState<UserLimitedInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [companyResponse, userResponse] = await Promise.all([
          getCompanySettings(),
          getUserLimitedInfo(),
        ]);
        setCompany(companyResponse);
        setUser(userResponse);
      } catch (err) {
        console.error("Error fetching settings:", err);
        setError("Failed to load settings");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="settings">
      <DashboardNavbar />
      <div className="content">
        {user ? (
          <SettingsUserInfoCard user={user} />
        ) : (
          <div>No user data available</div>
        )}
        {company ? (
          <SettingsCompanyInfoCard company={company} />
        ) : (
          <div>No company data available</div>
        )}
      </div>
    </div>
  );
};

export default Settings;
