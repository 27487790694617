import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Overview from "./pages/Overview/Overview";
import EmployeeDetail from "./pages/EmployeeDetail/EmployeeDetail";
import EmployeeDetailViewMoreHoursWorked from "./pages/EmployeeDetailViewMoreHoursWorked/EmployeeDetailViewMoreHoursWorked";
import Register from "./pages/Register/Register";
import Login from "./pages/Login/Login";
import Gigs from "./pages/Gigs/Gigs";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import FAQ from "./pages/FAQ/FAQ";
import Settings from "./pages/Settings/Settings";
import ProtectedRoute from "./components/ProtectedRoute";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

import SCC from "./pages/PrivacyPolicy/SCC/SCC";
import Disclaimer from "./pages/PrivacyPolicy/Disclaimer/Disclaimer";
import DataProcessingAgreement from "./pages/PrivacyPolicy/DataProcessingAgreement/DataProcessingAgreement";
import CookiePolicy from "./pages/PrivacyPolicy/CookiePolicy/CookiePolicy";
import EULA from "./pages/PrivacyPolicy/EULA/EULA";

function App() {
  return (
    <Router
      future={{
        v7_relativeSplatPath: true,
        v7_startTransition: true,
      }}
    >
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/scc" element={<SCC />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route
          path="/data-processing-agreement"
          element={<DataProcessingAgreement />}
        />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/eula" element={<EULA />} />

        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* Protected routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Overview />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard/employeedetail/:employeeId"
          element={
            <ProtectedRoute>
              <EmployeeDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard/employeedetail/hoursworked/:employeeId"
          element={
            <ProtectedRoute>
              <EmployeeDetailViewMoreHoursWorked />
            </ProtectedRoute>
          }
        />

        <Route
          path="/gigs"
          element={
            <ProtectedRoute>
              <Gigs />
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
