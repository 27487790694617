import React from "react";
import { Link } from "react-router-dom";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <div className="banner">
        <img
          src="/images/header/banner.png"
          alt="Banner"
          className="banner-image"
        />
      </div>

      <div className="privacy-policy-page-content">
        <Link to="/">
          <img src="/images/logo/logo.svg" alt="Logo" className="logo" />
        </Link>

        <div className="privacy-policy-container">
          <header>Privacy Policy</header>
          <section>
            <p>
              <strong>Last updated December 26, 2024</strong>
            </p>

            <p>
              This Privacy Policy for Managerize (&quot;<strong>we</strong>
              ,&quot; &quot;
              <strong>us</strong>,&quot; or &quot;<strong>our</strong>&quot;),
              describes how and why we might access, collect, store, use, and/or
              share (&quot;
              <strong>process</strong>&quot;) your personal information when you
              use our services (&quot;<strong>Services</strong>&quot;),
              including when you:
            </p>
            <ul>
              <li>
                Visit our website at{" "}
                <a href="https://managerize.com/">https://managerize.com</a>, or
                any website of ours that links to this Privacy Policy
              </li>
            </ul>

            <ul>
              <li>
                Download and use our mobile application (
                <strong>Managerize: Workforce</strong>), or any other
                application of ours that links to this Privacy Policy
              </li>
            </ul>

            <ul>
              <li>
                Engage with us in other related ways, including any sales,
                marketing, or events
              </li>
            </ul>
            <p>
              <strong>Questions or concerns? </strong>Reading this Privacy
              Policy will help you understand your privacy rights and choices.
              We are responsible for making decisions about how your personal
              information is processed. If you do not agree with our policies
              and practices, please do not use our Services. If you still have
              any questions or concerns, please contact us at{" "}
              <a href="mailto:contact@managerize.com">contact@managerize.com</a>
              .
            </p>

            <p style={{ textAlign: "center" }}>
              <a
                href="/data-processing-agreement"
                target="_blank"
                style={{ marginRight: "15px" }}
              >
                Data Processing Agreement
              </a>
              <span style={{ marginRight: "15px" }}>|</span>
              <a href="/eula" target="_blank" style={{ marginRight: "15px" }}>
                EULA
              </a>
              <span style={{ marginRight: "15px" }}>|</span>
              <a href="/scc" target="_blank" style={{ marginRight: "15px" }}>
                SCC
              </a>
              <span style={{ marginRight: "15px" }}>|</span>
              <a
                href="/disclaimer"
                target="_blank"
                style={{ marginRight: "15px" }}
              >
                Disclaimer
              </a>
              <span style={{ marginRight: "15px" }}>|</span>
              <a
                href="/cookie-policy"
                target="_blank"
                style={{ marginRight: "15px" }}
              >
                Cookie Policy
              </a>
            </p>

            <p>
              <strong>SUMMARY OF KEY POINTS</strong>
            </p>

            <p>
              <strong>
                <em>
                  This summary provides key points from our Privacy Policy, but
                  you can find out more details about any of these topics by
                  clicking the link following each key point or by using our{" "}
                </em>
              </strong>

              <strong>
                <em>table of contents</em>
              </strong>

              <strong>
                <em> below to find the section you are looking for.</em>
              </strong>
            </p>

            <p>
              <strong>What personal information do we process?</strong> When you
              visit, use, or navigate our Services, we may process personal
              information depending on how you interact with us and the
              Services, the choices you make, and the products and features you
              use. Learn more about personal information you disclose to us .
            </p>

            <p>
              <strong>
                Do we process any sensitive personal information?{" "}
              </strong>
              Some of the information may be considered &quot;special&quot; or
              &quot;sensitive&quot; in certain jurisdictions, for example your
              racial or ethnic origins, sexual orientation, and religious
              beliefs. We do not process sensitive personal information.
            </p>

            <p>
              <strong>Do we collect any information from third parties?</strong>{" "}
              We do not collect any information from third parties.
            </p>

            <p>
              <strong>How do we process your information?</strong> We process
              your information to provide, improve, and administer our Services,
              communicate with you, for security and fraud prevention, and to
              comply with law. We may also process your information for other
              purposes with your consent. We process your information only when
              we have a valid legal reason to do so.
            </p>

            <p>
              <strong>
                In what situations and with which types of parties do we share
                personal information?
              </strong>{" "}
              We may share information in specific situations and with specific
              categories of third parties.
            </p>

            <p>
              <strong>How do we keep your information safe?</strong> We have
              adequate organizational and technical processes and procedures in
              place to protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
            </p>

            <p>
              <strong>What are your rights?</strong> Depending on where you are
              located geographically, the applicable privacy law may mean you
              have certain rights regarding your personal information.
            </p>

            <p>
              <strong>How do you exercise your rights?</strong> The easiest way
              to exercise your rights is by contacting us. We will consider and
              act upon any request in accordance with applicable data protection
              laws.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>1. WHAT INFORMATION DO WE COLLECT?</strong>

            <p>
              <strong>Personal information you disclose to us</strong>
            </p>

            <p>
              <strong>
                <em>In Short: </em>
              </strong>
              <em>We collect personal information that you provide to us.</em>
            </p>

            <p>
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </p>

            <p>
              <strong>Personal Information Provided by You.</strong> The
              personal information that we collect depends on the context of
              your interactions with us and the Services, the choices you make,
              and the products and features you use. The personal information we
              collect may include the following:
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>Data Collected from Employers</strong>
            </p>
            <ul>
              <li>
                <strong>Name</strong>: To identify and manage the
                employer&apos;s account.
              </li>
              <li>
                <strong>Phone Number</strong>: To facilitate communication and
                account recovery.
              </li>
              <li>
                <strong>Email Address</strong>: To send account credentials,
                notifications, and updates.
              </li>
              <li>
                <strong>Mailing Address</strong>: To process correspondence and
                manage employer-related transactions.
              </li>
              <li>
                <strong>Job Title</strong>: To distinguish roles within the
                employer&rsquo;s organization.
              </li>
              <li>
                <strong>Passwords</strong>: To ensure secure access to the
                employer&apos;s account.
              </li>
              <li>
                <strong>Contact Preferences</strong>: To tailor communication
                methods based on the employer&rsquo;s preferences.
              </li>
              <li>
                <strong>Contact or Authentication Data</strong>: To verify
                identity and secure login credentials.
              </li>
              <li>
                <strong>Date of Birth</strong>: To confirm identity and comply
                with legal and regulatory requirements for employers.
              </li>
            </ul>
            <p>
              <strong>
                Data Collected for Employees (Entered by Employers)
              </strong>
            </p>
            <ul>
              <li>
                <strong>Name</strong>: To create and manage employee accounts.
              </li>
              <li>
                <strong>Phone Number</strong>: To enable communication related
                to work schedules and account recovery.
              </li>
              <li>
                <strong>Email Address</strong>: To provide employees with access
                credentials and relevant notifications.
              </li>
              <li>
                <strong>Mailing Address</strong>: To facilitate payment
                processing and official correspondence.
              </li>
              <li>
                <strong>Job Title</strong>: To associate specific roles and
                responsibilities with employees.
              </li>
              <li>
                <strong>Contact Preferences</strong>: To allow employers to
                configure communication methods on behalf of employees.
              </li>
              <li>
                <strong>Contact or Authentication Data</strong>: To ensure
                secure access through employer-assigned credentials.
              </li>
              <li>
                <strong>Date of Birth</strong>: To verify identity and comply
                with employment eligibility requirements.
              </li>
            </ul>
            <p>
              <strong>Note</strong>: Employees cannot directly input or modify
              these details; all employee data must be added by the employer.
              Employers must ensure that they have obtained the necessary
              consent from employees to input and manage their data on their
              behalf, including permissions to access location and camera
              features via the app.
            </p>

            <p>
              <strong>Sensitive Information.</strong> We do not process
              sensitive information.
            </p>

            <p>
              <strong>Payment Data.</strong> We may collect data necessary to
              process your payment if you choose to make purchases, such as your
              payment instrument number, and the security code associated with
              your payment instrument. All payment data is handled and stored by
              Stripe. You may find their privacy policy link(s) here:{" "}
              <a href="http://www.stripe.com/privacy">
                http://www.stripe.com/privacy
              </a>
              .
            </p>

            <p>
              <strong>Application Data.</strong> If you use our application(s),
              we also may collect the following information if you choose to
              provide us with access or permission:
            </p>
            <ul>
              <li>
                <em>Geolocation Information.</em> We may request access or
                permission to track location-based information from your mobile
                device, either continuously or while you are using our mobile
                application(s), to provide certain location-based services. If
                you wish to change our access or permissions, you may do so in
                your device&apos;s settings.
              </li>
            </ul>

            <ul>
              <li>
                <em>Mobile Device Access.</em> We may request access or
                permission to certain features from your mobile device,
                including your mobile device&apos;s bluetooth, calendar, camera,
                contacts, microphone, reminders, sensors, sms messages, storage,
                and other features. If you wish to change our access or
                permissions, you may do so in your device&apos;s settings.
              </li>
            </ul>

            <ul>
              <li>
                <em>Mobile Device Data.</em> We automatically collect device
                information (such as your mobile device ID, model, and
                manufacturer), operating system, version information and system
                configuration information, device and application identification
                numbers, browser type and version, hardware model Internet
                service provider and/or mobile carrier, and Internet Protocol
                (IP) address (or proxy server). If you are using our
                application(s), we may also collect information about the phone
                network associated with your mobile device, your mobile
                device&rsquo;s operating system or platform, the type of mobile
                device you use, your mobile device&rsquo;s unique device ID, and
                information about the features of our application(s) you
                accessed.
              </li>
            </ul>

            <ul>
              <li>
                <em>Push Notifications.</em> We may request to send you push
                notifications regarding your account or certain features of the
                application(s). If you wish to opt out from receiving these
                types of communications, you may turn them off in your
                device&apos;s settings.
              </li>
            </ul>
            <p>
              This information is primarily needed to maintain the security and
              operation of our application(s), for troubleshooting, and for our
              internal analytics and reporting purposes.
            </p>

            <p>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </p>

            <p>
              <strong>Information automatically collected</strong>
            </p>

            <p>
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                Some information &mdash; such as your Internet Protocol (IP)
                address and/or browser and device characteristics &mdash; is
                collected automatically when you visit our Services.
              </em>
            </p>

            <p>
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </p>

            <p>
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>

            <p>The information we collect includes:</p>
            <ul>
              <li>
                <em>Log and Usage Data.</em> Log and usage data is
                service-related, diagnostic, usage, and performance information
                our servers automatically collect when you access or use our
                Services and which we record in log files. Depending on how you
                interact with us, this log data may include your IP address,
                device information, browser type, and settings and information
                about your activity in the Services (such as the date/time
                stamps associated with your usage, pages and files viewed,
                searches, and other actions you take such as which features you
                use), device event information (such as system activity, error
                reports (sometimes called &quot;crash dumps&quot;), and hardware
                settings).
              </li>
            </ul>

            <ul>
              <li>
                <em>Device Data.</em> We collect device data such as information
                about your computer, phone, tablet, or other device you use to
                access the Services. Depending on the device used, this device
                data may include information such as your IP address (or proxy
                server), device and application identification numbers,
                location, browser type, hardware model, Internet service
                provider and/or mobile carrier, operating system, and system
                configuration information.
              </li>
            </ul>

            <ul>
              <li>
                <em>Location Data.</em> We collect location data such as
                information about your device&apos;s location, which can be
                either precise or imprecise. How much information we collect
                depends on the type and settings of the device you use to access
                the Services. For example, we may use GPS and other technologies
                to collect geolocation data that tells us your current location
                (based on your IP address). You can opt out of allowing us to
                collect this information either by refusing access to the
                information or by disabling your Location setting on your
                device. However, if you choose to opt out, you may not be able
                to use certain aspects of the Services.
              </li>
            </ul>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>2. HOW DO WE PROCESS YOUR INFORMATION?</strong>

            <p>
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                We process your information to provide, improve, and administer
                our Services, communicate with you, for security and fraud
                prevention, and to comply with law. We may also process your
                information for other purposes with your consent.
              </em>
            </p>

            <p>
              <strong>
                We process your personal information for a variety of reasons,
                depending on how you interact with our Services, including:
              </strong>
            </p>
            <ul>
              <li>
                <strong>
                  To facilitate account creation and authentication and
                  otherwise manage user accounts.{" "}
                </strong>
                We may process your information so you can create and log in to
                your account, as well as keep your account in working order.
              </li>
            </ul>

            <ul>
              <li>
                <strong>
                  To deliver and facilitate delivery of services to the user.{" "}
                </strong>
                We may process your information to provide you with the
                requested service.
              </li>
            </ul>

            <ul>
              <li>
                <strong>
                  To respond to user inquiries/offer support to users.{" "}
                </strong>
                We may process your information to respond to your inquiries and
                solve any potential issues you might have with the requested
                service.
              </li>
            </ul>

            <ul>
              <li>
                <strong>To send administrative information to you. </strong>We
                may process your information to send you details about our
                products and services, changes to our terms and policies, and
                other similar information.
              </li>
            </ul>

            <ul>
              <li>
                <strong>To enable user-to-user communications. </strong>We may
                process your information if you choose to use any of our
                offerings that allow for communication with another user.
              </li>
            </ul>

            <ul>
              <li>
                <strong>To request feedback. </strong>We may process your
                information when necessary to request feedback and to contact
                you about your use of our Services.
              </li>
            </ul>

            <ul>
              <li>
                <strong>
                  To send you marketing and promotional communications.{" "}
                </strong>
                We may process the personal information you send to us for our
                marketing purposes, if this is in accordance with your marketing
                preferences. You can opt out of our marketing emails at any
                time. For more information, see WHAT ARE YOUR PRIVACY RIGHTS?
                below.
              </li>
            </ul>

            <ul>
              <li>
                <strong>To deliver targeted advertising to you.</strong> We may
                process your information to develop and display personalized
                content and advertising tailored to your interests, location,
                and more.
              </li>
            </ul>

            <ul>
              <li>
                <strong>To protect our Services.</strong> We may process your
                information as part of our efforts to keep our Services safe and
                secure, including fraud monitoring and prevention.
              </li>
            </ul>

            <ul>
              <li>
                <strong>To identify usage trends.</strong> We may process
                information about how you use our Services to better understand
                how they are being used so we can improve them.
              </li>
            </ul>

            <ul>
              <li>
                <strong>
                  To determine the effectiveness of our marketing and
                  promotional campaigns.
                </strong>{" "}
                We may process your information to better understand how to
                provide marketing and promotional campaigns that are most
                relevant to you.
              </li>
            </ul>

            <ul>
              <li>
                <strong>
                  To save or protect an individual&apos;s vital interest.
                </strong>{" "}
                We may process your information when necessary to save or
                protect an individual&rsquo;s vital interest, such as to prevent
                harm.
              </li>
            </ul>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
            </strong>

            <p>
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                We only process your personal information when we believe it is
                necessary and we have a valid legal reason (i.e., legal basis)
                to do so under applicable law, like with your consent, to comply
                with laws, to provide you with services to enter into or fulfill
                our contractual obligations, to protect your rights, or to
                fulfill our legitimate business interests.
              </em>
            </p>

            <p>
              <strong>
                <em>
                  <u>
                    If you are located in the EU or UK, this section applies to
                    you.
                  </u>
                </em>
              </strong>
            </p>
            <p>
              <strong>
                <em>
                  <u>&nbsp;</u>
                </em>
              </strong>
            </p>
            <p>
              The General Data Protection Regulation (GDPR) and UK GDPR require
              us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following
              legal bases to process your personal information:
            </p>
            <ul>
              <li>
                We may process your information if you have given us permission
                (i.e., consent) to use your personal information for a specific
                purpose. You can withdraw your consent at any time.
              </li>
            </ul>

            <ul>
              <li>
                <strong>Performance of a Contract.</strong> We may process your
                personal information when we believe it is necessary to fulfill
                our contractual obligations to you, including providing our
                Services or at your request prior to entering into a contract
                with you.
              </li>
            </ul>

            <ul>
              <li>
                <strong>Legitimate Interests.</strong> We may process your
                information when we believe it is reasonably necessary to
                achieve our legitimate business interests and those interests do
                not outweigh your interests and fundamental rights and freedoms.
                For example, we may process your personal information for some
                of the purposes described in order to:
              </li>
            </ul>

            <ul>
              <li>
                Send users information about special offers and discounts on our
                products and services
              </li>
            </ul>

            <ul>
              <li>
                Develop and display personalized and relevant advertising
                content for our users
              </li>
            </ul>

            <ul>
              <li>
                Analyze how our Services are used so we can improve them to
                engage and retain users
              </li>
            </ul>

            <ul>
              <li>Support our marketing activities</li>
            </ul>

            <ul>
              <li>Diagnose problems and/or prevent fraudulent activities</li>
            </ul>

            <ul>
              <li>
                Understand how our users use our products and services so we can
                improve user experience
              </li>
            </ul>

            <ul>
              <li>
                <strong>Legal Obligations.</strong> We may process your
                information where we believe it is necessary for compliance with
                our legal obligations, such as to cooperate with a law
                enforcement body or regulatory agency, exercise or defend our
                legal rights, or disclose your information as evidence in
                litigation in which we are involved.
              </li>
            </ul>

            <ul>
              <li>
                <strong>Vital Interests.</strong> We may process your
                information where we believe it is necessary to protect your
                vital interests or the vital interests of a third party, such as
                situations involving potential threats to the safety of any
                person.
              </li>
            </ul>

            <p>
              <strong>
                <em>
                  <u>
                    If you are located in Canada, this section applies to you.
                  </u>
                </em>
              </strong>
            </p>

            <p>
              We may process your information if you have given us specific
              permission (i.e., express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e., implied consent). You can{" "}
              withdraw your consent at any time.
            </p>

            <p>
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </p>
            <ul>
              <li>
                If collection is clearly in the interests of an individual and
                consent cannot be obtained in a timely way
              </li>
            </ul>

            <ul>
              <li>For investigations and fraud detection and prevention</li>
            </ul>

            <ul>
              <li>
                For business transactions provided certain conditions are met
              </li>
            </ul>

            <ul>
              <li>
                If it is contained in a witness statement and the collection is
                necessary to assess, process, or settle an insurance claim
              </li>
            </ul>

            <ul>
              <li>
                For identifying injured, ill, or deceased persons and
                communicating with next of kin
              </li>
            </ul>

            <ul>
              <li>
                If we have reasonable grounds to believe an individual has been,
                is, or may be victim of financial abuse
              </li>
            </ul>

            <ul>
              <li>
                If it is reasonable to expect collection and use with consent
                would compromise the availability or the accuracy of the
                information and the collection is reasonable for purposes
                related to investigating a breach of an agreement or a
                contravention of the laws of Canada or a province
              </li>
            </ul>

            <ul>
              <li>
                If disclosure is required to comply with a subpoena, warrant,
                court order, or rules of the court relating to the production of
                records
              </li>
            </ul>

            <ul>
              <li>
                If it was produced by an individual in the course of their
                employment, business, or profession and the collection is
                consistent with the purposes for which the information was
                produced
              </li>
            </ul>

            <ul>
              <li>
                If the collection is solely for journalistic, artistic, or
                literary purposes
              </li>
            </ul>

            <ul>
              <li>
                If the information is publicly available and is specified by the
                regulations
              </li>
            </ul>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </strong>

            <p>
              <strong>
                <em>In Short:</em>
              </strong>
              <em>
                {" "}
                We may share information in specific situations described in
                this section and/or with the following categories of third
                parties.
              </em>
            </p>

            <p>
              <strong>
                Vendors, Consultants, and Other Third-Party Service Providers.
              </strong>{" "}
              We may share your data with third-party vendors, service
              providers, contractors, or agents (&quot;
              <strong>third parties</strong>
              &quot;) who perform services for us or on our behalf and require
              access to such information to do that work. We have contracts in
              place with our third parties, which are designed to help safeguard
              your personal information. This means that they cannot do anything
              with your personal information unless we have instructed them to
              do it. They will also not share your personal information with any
              organization apart from us. They also commit to protect the data
              they hold on our behalf and to retain it for the period we
              instruct.
            </p>

            <p>
              The third parties we may share personal information with are as
              follows:
            </p>
            <ol>
              <li>
                <strong> Mailgun</strong>
              </li>
            </ol>
            <p>
              We use Mailgun to manage and send email communications
              efficiently.
            </p>
            <ul>
              <li>
                <strong>Purpose</strong>: To deliver account credentials,
                notifications, and updates to employers and employees reliably
                and securely.
              </li>
            </ul>
            <ol>
              <li>
                <strong> Cloudflare</strong>
              </li>
            </ol>
            <p>We utilize Cloudflare for image storage and content delivery.</p>
            <ul>
              <li>
                <strong>Purpose</strong>: To securely store and manage profile
                images and other visual data while ensuring fast and efficient
                delivery to users.
              </li>
            </ul>
            <ol>
              <li>
                <strong> Digital Ocean</strong>
              </li>
            </ol>
            <p>
              We rely on Digital Ocean to host our database and server
              infrastructure.
            </p>
            <ul>
              <li>
                <strong>Purpose</strong>: To securely store employee and
                employer data, maintain application functionality, and ensure
                reliable performance for both web and mobile interfaces.
              </li>
            </ul>

            <p>
              We also may need to share your personal information in the
              following situations:
            </p>
            <ul>
              <li>
                <strong>Business Transfers.</strong> We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
              </li>
            </ul>

            <ul>
              <li>
                <strong>When we use Google Maps Platform APIs.</strong> We may
                share your information with certain Google Maps Platform APIs
                (e.g., Google Maps API, Places API). Google Maps uses GPS,
                Wi-Fi, and cell towers to estimate your location. GPS is
                accurate to about 20 meters, while Wi-Fi and cell towers help
                improve accuracy when GPS signals are weak, like indoors. This
                data helps Google Maps provide directions, but it is not always
                perfectly precise.
              </li>
            </ul>

            <ul>
              <li>
                We may share your information with our affiliates, in which case
                we will require those affiliates to honor this Privacy Policy.
                Affiliates include our parent company and any subsidiaries,
                joint venture partners, or other companies that we control or
                that are under common control with us.
              </li>
            </ul>

            <ul>
              <li>
                <strong>Business Partners.</strong> We may share your
                information with our business partners to offer you certain
                products, services, or promotions.
              </li>
            </ul>

            <ul>
              <li>
                <strong>Other Users.</strong> When you share personal
                information (for example, by posting comments, contributions, or
                other content to the Services) or otherwise interact with public
                areas of the Services, such personal information may be viewed
                by all users and may be publicly made available outside the
                Services in perpetuity. Similarly, other users will be able to
                view descriptions of your activity, communicate with you within
                our Services, and view your profile.
              </li>
            </ul>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </strong>

            <p>
              <strong>
                <em>In Short:</em>
              </strong>
              <em>
                {" "}
                We may use cookies and other tracking technologies to collect
                and store your information.
              </em>
            </p>

            <p>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to gather information when you interact with
              our Services. Some online tracking technologies help us maintain
              the security of our Services and your account, prevent crashes,
              fix bugs, save your preferences, and assist with basic site
              functions.
            </p>

            <p>
              We also permit third parties and service providers to use online
              tracking technologies on our Services for analytics and
              advertising, including to help manage and display advertisements,
              to tailor advertisements to your interests, or to send abandoned
              shopping cart reminders (depending on your communication
              preferences). The third parties and service providers use their
              technology to provide advertising about products and services
              tailored to your interests which may appear either on our Services
              or on other websites.
            </p>

            <p>
              To the extent these online tracking technologies are deemed to be
              a &quot;sale&quot;/&quot;sharing&quot; (which includes targeted
              advertising, as defined under the applicable laws) under
              applicable US state laws, you can opt out of these online tracking
              technologies by submitting a request as described below under
              section DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </p>

            <p>
              Specific information about how we use such technologies and how
              you can refuse certain cookies is set out in our Cookie Policy
              [PUT THE LINK TO YOUR COOKIE POLICY HERE].
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</strong>

            <p>
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                We may transfer, store, and process your information in
                countries other than your own.
              </em>
            </p>

            <p>
              Our servers are located in the United Kingdom. If you are
              accessing our Services from outside the United Kingdom, please be
              aware that your information may be transferred to, stored by, and
              processed by us in our facilities and in the facilities of the
              third parties with whom we may share your personal information (
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?), in the
              United States, and other countries.
            </p>

            <p>
              If you are a resident in the European Economic Area (EEA), United
              Kingdom (UK), or Switzerland, then these countries may not
              necessarily have data protection laws or other similar laws as
              comprehensive as those in your country. However, we will take all
              necessary measures to protect your personal information in
              accordance with this Privacy Policy and applicable law.
            </p>

            <p>
              <strong>
                European Commission&apos;s Standard Contractual Clauses:
              </strong>
            </p>

            <p>
              We have implemented measures to protect your personal information,
              including by using the European Commission&apos;s Standard
              Contractual Clauses for transfers of personal information between
              our group companies and between us and our third-party providers.
              These clauses require all recipients to protect all personal
              information that they process originating from the EEA or UK in
              accordance with European data protection laws and regulations. Our
              Data Processing Agreements that include Standard Contractual
              Clauses are available here: _[PUT THE LINK TO YOUR DPA HERE]_. We
              have implemented similar appropriate safeguards with our
              third-party service providers and partners and further details can
              be provided upon request.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>7. HOW LONG DO WE KEEP YOUR INFORMATION?</strong>

            <p>
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                We keep your information for as long as necessary to fulfill the
                purposes outlined in this Privacy Policy unless otherwise
                required by law.
              </em>
            </p>

            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this Privacy Policy, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us to keep your personal information for
              longer than the period of time in which users have an account with
              us.
            </p>

            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</strong>

            <p>
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                We aim to protect your personal information through a system of
                organizational and technical security measures.
              </em>
            </p>

            <p>
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>9. DO WE COLLECT INFORMATION FROM MINORS?</strong>

            <p>
              <strong>
                <em>In Short:</em>
              </strong>
              <em>
                {" "}
                We do not knowingly collect data from or market to children
                under 18 years of age.
              </em>
            </p>

            <p>
              We do not knowingly collect, solicit data from, or market to
              children under 18 years of age, nor do we knowingly sell such
              personal information. By using the Services, you represent that
              you are at least 18 or that you are the parent or guardian of such
              a minor and consent to such minor dependent&rsquo;s use of the
              Services. If we learn that personal information from users less
              than 18 years of age has been collected, we will deactivate the
              account and take reasonable measures to promptly delete such data
              from our records. If you become aware of any data we may have
              collected from children under the age of 18, please contact us at{" "}
              <a href="mailto:contact@managerize.com">contact@managerize.com</a>
              .
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>10. WHAT ARE YOUR PRIVACY RIGHTS?</strong>

            <p>
              <strong>
                <em>In Short:</em>
              </strong>
              <em>
                {" "}
                Depending on your state of residence in the US or in some
                regions, such as the European Economic Area (EEA), United
                Kingdom (UK), Switzerland, and Canada, you have rights that
                allow you greater access to and control over your personal
                information. You may review, change, or terminate your account
                at any time, depending on your country, province, or state of
                residence.
              </em>
            </p>

            <p>
              In some regions (like the EEA, UK, Switzerland, and Canada), you
              have certain rights under applicable data protection laws. These
              may include the right (i) to request access and obtain a copy of
              your personal information, (ii) to request rectification or
              erasure; (iii) to restrict the processing of your personal
              information; (iv) if applicable, to data portability; and (v) not
              to be subject to automated decision-making. In certain
              circumstances, you may also have the right to object to the
              processing of your personal information. You can make such a
              request by contacting us by using the contact details provided in
              the section HOW CAN YOU CONTACT US ABOUT THIS NOTICE? below.
            </p>

            <p>
              We will consider and act upon any request in accordance with
              applicable data protection laws.
            </p>

            <p>
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your{" "}
              <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                Member State data protection authority
              </a>{" "}
              or{" "}
              <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">
                UK data protection authority
              </a>
              .
            </p>

            <p>
              If you are located in Switzerland, you may contact the{" "}
              <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                Federal Data Protection and Information Commissioner
              </a>
              .
            </p>

            <p>
              <strong>
                <u>Withdrawing your consent:</u>
              </strong>{" "}
              If we are relying on your consent to process your personal
              information, which may be express and/or implied consent depending
              on the applicable law, you have the right to withdraw your consent
              at any time. You can withdraw your consent at any time by
              contacting us by using the contact details provided in the section
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE? below or updating your
              preferences.
            </p>

            <p>
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </p>

            <p>
              <strong>
                <u>Opting out of marketing and promotional communications:</u>{" "}
              </strong>
              You can unsubscribe from our marketing and promotional
              communications at any time by clicking on the unsubscribe link in
              the emails that we send, or by contacting us using the details
              provided in the section HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              below. You will then be removed from the marketing lists. However,
              we may still communicate with you &mdash; for example, to send you
              service-related messages that are necessary for the administration
              and use of your account, to respond to service requests, or for
              other non-marketing purposes.
            </p>

            <p>
              <strong>Account Information</strong>
            </p>

            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <ul>
              <li>
                Log in to your account settings and update your user account.
              </li>
            </ul>

            <ul>
              <li>Contact us using the contact information provided.</li>
            </ul>

            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </p>

            <p>
              <strong>
                <u>Cookies and similar technologies:</u>
              </strong>{" "}
              Most Web browsers are set to accept cookies by default. If you
              prefer, you can usually choose to set your browser to remove
              cookies and to reject cookies. If you choose to remove cookies or
              reject cookies, this could affect certain features or services of
              our Services.
            </p>

            <p>
              If you have questions or comments about your privacy rights, you
              may email us at{" "}
              <a href="mailto:contact@managerize.com">contact@managerize.com</a>
              .
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>11. CONTROLS FOR DO-NOT-TRACK FEATURES</strong>

            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (&quot;DNT&quot;) feature or
              setting you can activate to signal your privacy preference not to
              have data about your online browsing activities monitored and
              collected. At this stage, no uniform technology standard for
              recognizing and implementing DNT signals has been finalized. As
              such, we do not currently respond to DNT browser signals or any
              other mechanism that automatically communicates your choice not to
              be tracked online. If a standard for online tracking is adopted
              that we must follow in the future, we will inform you about that
              practice in a revised version of this Privacy Policy.
            </p>

            <p>
              California law requires us to let you know how we respond to web
              browser DNT signals. Because there currently is not an industry or
              legal standard for recognizing or honoring DNT signals, we do not
              respond to them at this time.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </strong>

            <p>
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                If you are a resident of California, Colorado, Connecticut,
                Delaware, Florida, Indiana, Iowa, Kentucky, Minnesota, Montana,
                Nebraska, New Hampshire, New Jersey, Oregon, Tennessee, Texas,
                Utah, or Virginia, you may have the right to request access to
                and receive details about the personal information we maintain
                about you and how we have processed it, correct inaccuracies,
                get a copy of, or delete your personal information. You may also
                have the right to withdraw your consent to our processing of
                your personal information. These rights may be limited in some
                circumstances by applicable law. More information is provided
                below.
              </em>
            </p>

            <p>
              <strong>Categories of Personal Information We Collect</strong>
            </p>

            <p>
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </p>

            <table width="624">
              <tbody>
                <tr>
                  <td width="211">
                    <p>
                      <strong>Category</strong>
                    </p>
                  </td>
                  <td width="317">
                    <p>
                      <strong>Examples</strong>
                    </p>
                  </td>
                  <td width="96">
                    <p>
                      <strong>Collected</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td width="211">
                    <p>A. Identifiers</p>
                  </td>
                  <td width="317">
                    <p>
                      Contact details, such as real name, alias, postal address,
                      telephone or mobile contact number, unique personal
                      identifier, online identifier, Internet Protocol address,
                      email address, and account name
                    </p>
                  </td>
                  <td width="96">
                    <p>YES</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <table width="624">
              <tbody>
                <tr>
                  <td width="211">
                    <p>
                      B. Personal information as defined in the California
                      Customer Records statute
                    </p>
                  </td>
                  <td width="317">
                    <p>
                      Name, contact information, education, employment,
                      employment history, and financial information
                    </p>
                  </td>
                  <td width="96">
                    <p>YES</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <table width="624">
              <tbody>
                <tr>
                  <td width="211">
                    <p>
                      C. Protected classification characteristics under state or
                      federal law
                    </p>
                  </td>
                  <td width="317">
                    <p>
                      Gender, age, date of birth, race and ethnicity, national
                      origin, marital status, and other demographic data
                    </p>
                  </td>
                  <td width="96">
                    <p>YES</p>
                  </td>
                </tr>
                <tr>
                  <td width="211">
                    <p>D. Commercial information</p>
                  </td>
                  <td width="317">
                    <p>
                      Transaction information, purchase history, financial
                      details, and payment information
                    </p>
                  </td>
                  <td width="96">
                    <p>NO</p>
                  </td>
                </tr>
                <tr>
                  <td width="211">
                    <p>E. Biometric information</p>
                  </td>
                  <td width="317">
                    <p>Fingerprints and voiceprints</p>
                  </td>
                  <td width="96">
                    <p>YES</p>
                  </td>
                </tr>
                <tr>
                  <td width="211">
                    <p>F. Internet or other similar network activity</p>
                  </td>
                  <td width="317">
                    <p>
                      Browsing history, search history, online behavior,
                      interest data, and interactions with our and other
                      websites, applications, systems, and advertisements
                    </p>
                  </td>
                  <td width="96">
                    <p>NO</p>
                  </td>
                </tr>
                <tr>
                  <td width="211">
                    <p>G. Geolocation data</p>
                  </td>
                  <td width="317">
                    <p>Device location</p>
                  </td>
                  <td width="96">
                    <p>YES</p>
                  </td>
                </tr>
                <tr>
                  <td width="211">
                    <p>H. Audio, electronic, sensory, or similar information</p>
                  </td>
                  <td width="317">
                    <p>
                      Images and audio, video or call recordings created in
                      connection with our business activities
                    </p>
                  </td>
                  <td width="96">
                    <p>YES</p>
                  </td>
                </tr>
                <tr>
                  <td width="211">
                    <p>I. Professional or employment-related information</p>
                  </td>
                  <td width="317">
                    <p>
                      Business contact details in order to provide you our
                      Services at a business level or job title, work history,
                      and professional qualifications if you apply for a job
                      with us
                    </p>
                  </td>
                  <td width="96">
                    <p>YES</p>
                  </td>
                </tr>
                <tr>
                  <td width="211">
                    <p>J. Education Information</p>
                  </td>
                  <td width="317">
                    <p>Student records and directory information</p>
                  </td>
                  <td width="96">
                    <p>NO</p>
                  </td>
                </tr>
                <tr>
                  <td width="211">
                    <p>
                      K. Inferences drawn from collected personal information
                    </p>
                  </td>
                  <td width="317">
                    <p>
                      Inferences drawn from any of the collected personal
                      information listed above to create a profile or summary
                      about, for example, an individual&rsquo;s preferences and
                      characteristics
                    </p>
                  </td>
                  <td width="96">
                    <p>YES</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <p>
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
            </p>
            <ul>
              <li>Receiving help through our customer support channels;</li>
            </ul>

            <ul>
              <li>Participation in customer surveys or contests; and</li>
            </ul>

            <ul>
              <li>
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </li>
            </ul>
            <p>
              <strong>Sources of Personal Information</strong>
            </p>

            <p>
              Learn more about the sources of personal information we collect in
              WHAT INFORMATION DO WE COLLECT?
            </p>

            <p>
              <strong>How We Use and Share Personal Information</strong>
            </p>

            <p>
              Learn more about how we use your personal information in the
              section, HOW DO WE PROCESS YOUR INFORMATION?
            </p>

            <p>
              <strong>Will your information be shared with anyone else?</strong>
            </p>

            <p>
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Learn more about how we disclose personal
              information to in the section, WHEN AND WITH WHOM DO WE SHARE YOUR
              PERSONAL INFORMATION?
            </p>

            <p>
              We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be &quot;selling&quot; of your personal information.
            </p>

            <p>
              We have not sold or shared any personal information to third
              parties for a business or commercial purpose in the preceding
              twelve (12) months.
            </p>

            <p>
              The categories of third parties to whom we disclosed personal
              information for a business or commercial purpose can be found
              under WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </p>

            <p>
              <strong>Your Rights</strong>
            </p>

            <p>
              You have rights under certain US state data protection laws.
              However, these rights are not absolute, and in certain cases, we
              may decline your request as permitted by law. These rights
              include:
            </p>
            <ul>
              <li>
                <strong>Right to know</strong> whether or not we are processing
                your personal data
              </li>
            </ul>

            <ul>
              <li>
                <strong>Right to access </strong>your personal data
              </li>
            </ul>

            <ul>
              <li>
                <strong>Right to correct </strong>inaccuracies in your personal
                data
              </li>
            </ul>

            <ul>
              <li>
                <strong>Right to request</strong> the deletion of your personal
                data
              </li>
            </ul>

            <ul>
              <li>
                <strong>Right to obtain a copy </strong>of the personal data you
                previously shared with us
              </li>
            </ul>

            <ul>
              <li>
                <strong>Right to non-discrimination</strong> for exercising your
                rights
              </li>
            </ul>

            <ul>
              <li>
                <strong>Right to opt out</strong> of the processing of your
                personal data if it is used for targeted advertising (or sharing
                as defined under California&rsquo;s privacy law), the sale of
                personal data, or profiling in furtherance of decisions that
                produce legal or similarly significant effects
                (&quot;profiling&quot;)
              </li>
            </ul>

            <p>
              Depending upon the state where you live, you may also have the
              following rights:
            </p>
            <ul>
              <li>
                Right to access the categories of personal data being processed
                (as permitted by applicable law, including Minnesota&rsquo;s
                privacy law)
              </li>
            </ul>

            <ul>
              <li>
                Right to obtain a list of the categories of third parties to
                which we have disclosed personal data (as permitted by
                applicable law, including California&apos;s and Delaware&apos;s
                privacy law)
              </li>
            </ul>

            <ul>
              <li>
                Right to obtain a list of specific third parties to which we
                have disclosed personal data (as permitted by applicable law,
                including Minnesota&apos;s and Oregon&apos;s privacy law)
              </li>
            </ul>

            <ul>
              <li>
                Right to review, understand, question, and correct how personal
                data has been profiled (as permitted by applicable law,
                including Minnesota&rsquo;s privacy law)
              </li>
            </ul>

            <ul>
              <li>
                Right to limit use and disclosure of sensitive personal data (as
                permitted by applicable law, including California&rsquo;s
                privacy law)
              </li>
            </ul>

            <ul>
              <li>
                Right to opt out of the collection of sensitive data and
                personal data collected through the operation of a voice or
                facial recognition feature (as permitted by applicable law,
                including Florida&rsquo;s privacy law)
              </li>
            </ul>

            <p>
              <strong>How to Exercise Your Rights</strong>
            </p>

            <p>
              To exercise these rights, you can contact us by emailing us at{" "}
              <a href="mailto:contact@managerize.com">contact@managerize.com</a>
              , or by referring to the contact details at the bottom of this
              document.
            </p>

            <p>
              We will honor your opt-out preferences if you enact the{" "}
              <a href="https://globalprivacycontrol.org/">
                Global Privacy Control
              </a>{" "}
              (GPC) opt-out signal on your browser.
            </p>

            <p>
              Under certain US state data protection laws, you can designate an
              authorized agent to make a request on your behalf. We may deny a
              request from an authorized agent that does not submit proof that
              they have been validly authorized to act on your behalf in
              accordance with applicable laws.
            </p>

            <p>
              <strong>Request Verification</strong>
            </p>

            <p>
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. We will only use personal information
              provided in your request to verify your identity or authority to
              make the request. However, if we cannot verify your identity from
              the information already maintained by us, we may request that you
              provide additional information for the purposes of verifying your
              identity and for security or fraud-prevention purposes.
            </p>

            <p>
              If you submit the request through an authorized agent, we may need
              to collect additional information to verify your identity before
              processing your request and the agent will need to provide a
              written and signed permission from you to submit such request on
              your behalf.
            </p>

            <p>
              <strong>Appeals</strong>
            </p>

            <p>
              Under certain US state data protection laws, if we decline to take
              action regarding your request, you may appeal our decision by
              emailing us at{" "}
              <a href="mailto:contact@managerize.com">contact@managerize.com</a>
              . We will inform you in writing of any action taken or not taken
              in response to the appeal, including a written explanation of the
              reasons for the decisions. If your appeal is denied, you may
              submit a complaint to your state attorney general.
            </p>

            <p>
              <strong>California &quot;Shine The Light&quot; Law</strong>
            </p>

            <p>
              California Civil Code Section 1798.83, also known as the
              &quot;Shine The Light&quot; law, permits our users who are
              California residents to request and obtain from us, once a year
              and free of charge, information about categories of personal
              information (if any) we disclosed to third parties for direct
              marketing purposes and the names and addresses of all third
              parties with which we shared personal information in the
              immediately preceding calendar year. If you are a California
              resident and would like to make such a request, please submit your
              request in writing to us by using the contact details provided in
              the section HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>13. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</strong>

            <p>
              <strong>
                <em>In Short:</em>
              </strong>
              <em>
                {" "}
                You may have additional rights based on the country you reside
                in.
              </em>
            </p>

            <p>
              <strong>Australia</strong> <strong>and</strong>{" "}
              <strong>New Zealand</strong>
            </p>

            <p>
              We collect and process your personal information under the
              obligations and conditions set by Australia&apos;s Privacy Act
              1988 and New Zealand&apos;s Privacy Act 2020 (Privacy Act).
            </p>

            <p>
              This Privacy Policy satisfies the notice requirements defined in
              both Privacy Acts, in particular: what personal information we
              collect from you, from which sources, for which purposes, and
              other recipients of your personal information.
            </p>

            <p>
              If you do not wish to provide the personal information necessary
              to fulfill their applicable purpose, it may affect our ability to
              provide our services, in particular:
            </p>

            <ul>
              <li>offer you the products or services that you want</li>
            </ul>

            <ul>
              <li>respond to or help with your requests</li>
            </ul>

            <ul>
              <li>manage your account with us</li>
            </ul>

            <ul>
              <li>confirm your identity and protect your account</li>
            </ul>
            <p>
              At any time, you have the right to request access to or correction
              of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </p>

            <p>
              If you believe we are unlawfully processing your personal
              information, you have the right to submit a complaint about a
              breach of the Australian Privacy Principles to the{" "}
              <a href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us">
                Office of the Australian Information Commissioner
              </a>{" "}
              and a breach of New Zealand&apos;s Privacy Principles to the{" "}
              <a href="https://www.privacy.org.nz/your-rights/making-a-complaint/">
                Office of New Zealand Privacy Commissioner
              </a>
              .
            </p>

            <p>
              <strong>Republic of South Africa</strong>
            </p>

            <p>
              At any time, you have the right to request access to or correction
              of your personal information. You can make such a request by
              contacting us by using the contact details provided in the section
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </p>

            <p>
              If you are unsatisfied with the manner in which we address any
              complaint with regard to our processing of personal information,
              you can contact the office of the regulator, the details of which
              are:
            </p>

            <p>
              <a href="https://inforegulator.org.za/">
                The Information Regulator (South Africa)
              </a>
            </p>
            <p>
              General enquiries:{" "}
              <a href="mailto:enquiries@inforegulator.org.za">
                enquiries@inforegulator.org.za
              </a>
            </p>
            <p>
              Complaints (complete POPIA/PAIA form 5):{" "}
              <a href="mailto:PAIAComplaints@inforegulator.org.za">
                PAIAComplaints@inforegulator.org.za
              </a>{" "}
              &amp;{" "}
              <a href="mailto:POPIAComplaints@inforegulator.org.za">
                POPIAComplaints@inforegulator.org.za
              </a>
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>14. DO WE MAKE UPDATES TO THIS NOTICE?</strong>

            <p>
              <strong>
                <em>In Short: </em>
              </strong>
              <em>
                Yes, we will update this notice as necessary to stay compliant
                with relevant laws.
              </em>
            </p>

            <p>
              We may update this Privacy Policy from time to time. The updated
              version will be indicated by an updated &quot;Revised&quot; date
              at the top of this Privacy Policy. If we make material changes to
              this Privacy Policy, we may notify you either by prominently
              posting a notice of such changes or by directly sending you a
              notification. We encourage you to review this Privacy Policy
              frequently to be informed of how we are protecting your
              information.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</strong>

            <p>
              If you have questions or comments about this notice, you may email
              us at{" "}
              <a href="mailto:contact@managerize.com">contact@managerize.com</a>
              .
            </p>
            <p>
              <strong>Managerize</strong>
            </p>
            <p>__________</p>
            <p>__________, WY</p>
            <p>United States</p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>
              16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </strong>

            <p>
              Based on the applicable laws of your country or state of residence
              in the US, you may have the right to request access to the
              personal information we collect from you, details about how we
              have processed it, correct inaccuracies, or delete your personal
              information. You may also have the right to withdraw your consent
              to our processing of your personal information. These rights may
              be limited in some circumstances by applicable law. To request to
              review, update, or delete your personal information, please email
              us at:{" "}
              <a href="mailto:contact@managerize.com">contact@managerize.com</a>
              .
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
