import React, { useState, useEffect, useCallback } from "react";
import "./EmployeeDetailEarnings.css";
import { calculateEmployeePay } from "../../apiService/apiService";
import Loader from "../Loader/Loader";
import { formatIsoStringWithAmPm } from "../../utilities/utilities";
import { Attendance } from "../../constants/interfaces";

interface EmployeeDetailEarningsProps {
  employeeId: string;
  paymentType: string;
}

const EmployeeDetailEarnings = ({
  employeeId,
  paymentType,
}: EmployeeDetailEarningsProps) => {
  const [timeframe, setTimeframe] = useState<string>("weekly");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [customDate, setCustomDate] = useState<string>("");
  const [earnings, setEarnings] = useState<{
    totalHours: number;
    totalPay: number;
    attendances: Attendance[];
    periodStart: string;
    periodEnd: string;
  }>({
    totalHours: 0,
    totalPay: 0,
    attendances: [],
    periodStart: "",
    periodEnd: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const resetEarnings = () => {
    setEarnings({
      totalHours: 0,
      totalPay: 0,
      attendances: [],
      periodStart: "",
      periodEnd: "",
    });
  };

  const handleTimeframeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedTimeframe = e.target.value;
    setTimeframe(selectedTimeframe);
    resetEarnings();

    if (
      selectedTimeframe !== "customRange" &&
      selectedTimeframe !== "customSingle"
    ) {
      setStartDate("");
      setEndDate("");
      setCustomDate("");
      calculateEarnings(selectedTimeframe, "", "", "");
    }
  };

  const handleDateChangeForRange = (
    newStartDate: string,
    newEndDate: string,
  ) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    if (newStartDate && newEndDate) {
      calculateEarnings("customRange", newStartDate, newEndDate, "");
    }
  };

  const handleDateChangeForSingle = (newCustomDate: string) => {
    setCustomDate(newCustomDate);
    if (newCustomDate) {
      calculateEarnings("customSingle", "", "", newCustomDate);
    }
  };

  const calculateEarnings = useCallback(
    async (
      selectedTimeframe: string,
      start: string,
      end: string,
      custom: string,
    ) => {
      let response;

      try {
        setLoading(true);

        const startDateObj = start ? new Date(start) : null;
        const endDateObj = end ? new Date(end) : null;
        const customDateObj = custom ? new Date(custom) : null;

        if (startDateObj && isNaN(startDateObj.getTime())) {
          throw new Error("Invalid start date");
        }
        if (endDateObj && isNaN(endDateObj.getTime())) {
          throw new Error("Invalid end date");
        }
        if (customDateObj && isNaN(customDateObj.getTime())) {
          throw new Error("Invalid custom date");
        }

        if (selectedTimeframe === "customRange") {
          response = await calculateEmployeePay(
            employeeId,
            "customrange",
            startDateObj,
            endDateObj,
          );
        } else if (selectedTimeframe === "customSingle") {
          response = await calculateEmployeePay(
            employeeId,
            "customday",
            customDateObj,
          );
        } else {
          response = await calculateEmployeePay(employeeId, selectedTimeframe);
        }

        if (
          response &&
          response.totalHoursWorked != null &&
          response.totalPay != null
        ) {
          setEarnings({
            totalHours: response.totalHoursWorked,
            totalPay: response.totalPay,
            attendances: response.attendances,
            periodStart: response.periodStart,
            periodEnd: response.periodEnd,
          });
        } else {
          console.warn("No earnings found in response:", response);
          setEarnings({
            totalHours: 0,
            totalPay: 0,
            attendances: [],
            periodStart: "",
            periodEnd: "",
          });
        }
      } catch (error) {
        console.error("Error calculating earnings:", error);
        setEarnings({
          totalHours: 0,
          totalPay: 0,
          attendances: [],
          periodStart: "",
          periodEnd: "",
        });
      } finally {
        setLoading(false);
      }
    },
    [employeeId],
  );

  useEffect(() => {
    calculateEarnings("weekly", "", "", "");
  }, [employeeId, calculateEarnings]);

  return (
    <div className="employee-detail-earnings">
      <div className="card">
        <div className="selection">
          <h2 className="header">Employee Earnings</h2>

          {paymentType === "Salary" ? (
            <div className="salary-message">
              Widget only available for <span className="hourly">hourly</span>{" "}
              employees
            </div>
          ) : (
            <>
              <div className="timeframe-selector">
                {[
                  "daily",
                  "weekly",
                  "monthly",
                  "customRange",
                  "customSingle",
                ].map((value) => (
                  <label key={value}>
                    <input
                      type="radio"
                      value={value}
                      checked={timeframe === value}
                      onChange={handleTimeframeChange}
                    />
                    {value === "customRange"
                      ? "Custom Range"
                      : value.charAt(0).toUpperCase() +
                        value.slice(1).replace("Single", " Day")}
                  </label>
                ))}
              </div>

              {timeframe === "customRange" && (
                <div className="custom-date-picker">
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) =>
                      handleDateChangeForRange(e.target.value, endDate)
                    }
                    className="date-input"
                  />
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) =>
                      handleDateChangeForRange(startDate, e.target.value)
                    }
                    className="date-input"
                  />
                </div>
              )}

              {timeframe === "customSingle" && (
                <div className="custom-single-date-picker">
                  <input
                    type="date"
                    value={customDate}
                    onChange={(e) => handleDateChangeForSingle(e.target.value)}
                    className="date-input"
                  />
                </div>
              )}
            </>
          )}
        </div>

        {/* This Loader and results block should be conditionally rendered for "Salary" */}
        {paymentType !== "Salary" && (
          <>
            <Loader isLoading={loading} />

            {!loading && (
              <div className="results">
                <h2 className="header attendance-records-title">
                  Attendance Records
                </h2>

                <div className="earnings-display">
                  <h3 className="earnings-title">
                    Total Hours Worked: {earnings.totalHours.toFixed(2)}
                  </h3>
                  <h3 className="earnings-title">
                    Total Pay: ${earnings.totalPay.toFixed(2)}
                  </h3>
                  <h3 className="earnings-title">
                    Period: {earnings.periodStart.slice(0, 10)} /{" "}
                    {earnings.periodEnd.slice(0, 10)}
                  </h3>
                </div>

                <div className="attendance-table-container">
                  <table className="attendance-table">
                    <thead>
                      <tr>
                        <th>Clock In</th>
                        <th>Clock Out</th>
                        <th>Hourly Rate</th>
                      </tr>
                    </thead>
                    <tbody>
                      {earnings.attendances.map((attendance, index) => (
                        <tr key={index}>
                          <td>{formatIsoStringWithAmPm(attendance.clockIn)}</td>
                          <td>
                            {attendance.clockOut
                              ? formatIsoStringWithAmPm(attendance.clockOut)
                              : "Still clocked in..."}
                          </td>
                          <td>${attendance.paymentAmount.toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeeDetailEarnings;
