import React, { useState } from "react";
import { UserLimitedInfo } from "../../constants/interfaces";
import { deleteUser } from "../../apiService/apiService";
import { useNavigate } from "react-router-dom";
import "./SettingsUserInfoCard.css";

interface SettingsUserInfoCardProps {
  user: UserLimitedInfo;
}

const SettingsUserInfoCard = ({ user }: SettingsUserInfoCardProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  const handleDelete = async () => {
    try {
      await deleteUser();
      navigate("/");
      alert("User deleted successfully.");
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("There was an error deleting the user.");
    }
  };

  return (
    <div className="settings-user-info-card">
      <div className="user-card">
        <h2>{user.fullName}</h2>
        <div className="user-info">
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>
            <strong>Phone Number:</strong> {user.phoneNumber}
          </p>
          <p>
            <strong>Date of Birth:</strong>{" "}
            {new Date(user.dateOfBirth).toLocaleDateString()}
          </p>
        </div>

        <div className="delete-section">
          <button className="delete-button" onClick={() => setIsDeleting(true)}>
            Delete User
          </button>

          {isDeleting && (
            <div className="warning-section">
              <p className="warning-text">
                ⚠️ <strong>Warning!</strong> Deleting your account is permanent
                and cannot be undone.
              </p>
              <div className="confirmation-buttons">
                <button className="confirm-delete" onClick={handleDelete}>
                  Yes, Delete My Account
                </button>
                <button
                  className="cancel-delete"
                  onClick={() => setIsDeleting(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsUserInfoCard;
