import React from "react";
import { Link } from "react-router-dom";
import "./Disclaimer.css";

const Disclaimer = () => {
  return (
    <div className="disclaimer-page">
      <div className="banner">
        <img
          src="/images/header/banner.png"
          alt="Banner"
          className="banner-image"
        />
      </div>

      <div className="disclaimer-page-content">
        <Link to="/">
          <img src="/images/logo/logo.svg" alt="Logo" className="logo" />
        </Link>

        <div className="disclaimer-container">
          <header>Disclaimer</header>
          <section>
            <p>
              <strong>Last updated December 26, 2024</strong>
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>Website Disclaimer</strong>

            <p>
              The information provided by Managerize (&quot;we,&quot;
              &quot;us,&quot; or &quot;our&quot;) on{" "}
              <a href="https://managerize.com/">https://managerize.com</a> (the
              &quot;Site&quot;) and our mobile application (
              <strong>Managerize: Workforce</strong>) is for general
              informational purposes only. All information on the Site and our
              mobile application is provided in good faith, however, we make no
              representation or warranty of any kind, express or implied,
              regarding the accuracy, adequacy, validity, reliability,
              availability, or completeness of any information on the Site or
              our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY
              LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
              RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR
              RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE AND OUR MOBILE
              APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND
              YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND OUR MOBILE
              APPLICATION IS SOLELY AT YOUR OWN RISK.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>External links disclaimer</strong>

            <p>
              The Site and our mobile application may contain (or you may be
              sent through the Site or our mobile application) links to other
              websites or content belonging to or originating from third parties
              or links to websites and features in banners or other advertising.
              Such external links are not investigated, monitored, or checked
              for accuracy, adequacy, validity, reliability, availability, or
              completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR
              ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY
              INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE
              SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER
              ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
              RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
              THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
            </p>
            <p>
              <strong>&nbsp;</strong>
            </p>
            <strong>Testemonials disclaimer</strong>

            <p>
              The Site may contain testimonials by users of our products and/or
              services. These testimonials reflect the real-life experiences and
              opinions of such users. However, the experiences are personal to
              those particular users, and may not necessarily be representative
              of all users of our products and/or services. We do not claim, and
              you should not assume, that all users will have the same
              experiences. YOUR INDIVIDUAL RESULTS MAY VARY.
            </p>

            <p>
              The testimonials on the Site are submitted in various forms such
              as text, audio and/or video, and are reviewed by us before being
              posted. They appear on the Site verbatim as given by the users,
              except for the correction of grammar or typing errors. Some
              testimonials may have been shortened for the sake of brevity where
              the full testimonial contained extraneous information not relevant
              to the general public.
            </p>

            <p>
              The views and opinions contained in the testimonials belong solely
              to the individual user and do not reflect our views and opinions.
              We are not affiliated with users who provide testimonials, and
              users are not paid or otherwise compensated for their
              testimonials.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
