import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import "./BurgerMenu.css";

const BurgerMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state: { isOpen: boolean }) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className="burger-menu-container">
      <Menu isOpen={menuOpen} onStateChange={handleStateChange}>
        <a className="burger-menu-item" href="/" onClick={closeMenu}>
          Home
        </a>

        <a className="burger-menu-item" href="/#pricing" onClick={closeMenu}>
          Pricing
        </a>

        <a className="burger-menu-item" href="/#demo" onClick={closeMenu}>
          Book a demo
        </a>

        <a className="burger-menu-item" href="/FAQ" onClick={closeMenu}>
          FAQ
        </a>

        <a
          className="burger-menu-item"
          href="/terms-and-conditions"
          onClick={closeMenu}
        >
          Terms and Conditions
        </a>

        <a
          className="burger-menu-item"
          href="/privacy-policy"
          onClick={closeMenu}
        >
          Privacy Policy
        </a>
      </Menu>
    </div>
  );
};

export default BurgerMenu;
