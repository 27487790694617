import React, { useState } from "react";
import { updateCompanySettings } from "../../apiService/apiService";
import { COUNTRIES } from "../../constants/countries";
import "./SettingsCompanyInfoCard.css";
import { Company, CompanyData } from "../../constants/interfaces";

interface SettingsCompanyInfoCardProps {
  company: Company;
}

const SettingsCompanyInfoCard = ({ company }: SettingsCompanyInfoCardProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState<CompanyData>({
    companyName: company.companyName,
    city: company.city,
    country: company.country,
    phoneNumber: company.phoneNumber,
    email: company.email,
    website: company.website,
  });
  const [submitting, setSubmitting] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
    setCompanyData({
      companyName: company.companyName,
      city: company.city,
      country: company.country,
      phoneNumber: company.phoneNumber,
      email: company.email,
      website: company.website,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to edit this company?")) {
      setSubmitting(true);
      try {
        const updatedCompanyData: CompanyData = { ...companyData };
        await updateCompanySettings(company.companyId, updatedCompanyData);
        setModalOpen(false);
        window.location.reload();
      } catch (error) {
        console.error("Failed to update company settings:", error);
        alert("Error updating company settings. Please try again.");
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <div className="settings-info-card">
      <div className="company-card">
        <h2>{company.companyName}</h2>
        <div className="company-info">
          <p>
            <strong>Country:</strong> {company.country}
          </p>
          <p>
            <strong>City:</strong> {company.city}
          </p>
          <p>
            <strong>Phone Number:</strong> {company.phoneNumber}
          </p>
          <p>
            <strong>Email:</strong> {company.email}
          </p>
          <p>
            <strong>Website:</strong> {company.website}
          </p>
        </div>
        <div className="company-actions">
          <button className="edit-button" onClick={() => setModalOpen(true)}>
            Edit
          </button>
        </div>
      </div>

      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <span className="close-x" onClick={handleModalClose}>
              <img src="/icons/x-icon.svg" alt="Close" />
            </span>
            <h2>Edit</h2>
            <form onSubmit={handleSubmit}>
              <div className="label-and-input-pair">
                <label>Company Name:</label>
                <input
                  type="text"
                  value={companyData.companyName}
                  onChange={(e) =>
                    setCompanyData({
                      ...companyData,
                      companyName: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="label-and-input-pair">
                <label>Country:</label>
                <select
                  value={companyData.country}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, country: e.target.value })
                  }
                  required
                >
                  <option value="">Select a country</option>
                  {COUNTRIES.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
              <div className="label-and-input-pair">
                <label>City:</label>
                <input
                  type="text"
                  value={companyData.city}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, city: e.target.value })
                  }
                />
              </div>
              <div className="label-and-input-pair">
                <label>Phone Number:</label>
                <input
                  type="text"
                  value={companyData.phoneNumber}
                  onChange={(e) =>
                    setCompanyData({
                      ...companyData,
                      phoneNumber: e.target.value,
                    })
                  }
                />
              </div>
              <div className="label-and-input-pair">
                <label>Email:</label>
                <input
                  type="email"
                  value={companyData.email}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, email: e.target.value })
                  }
                />
              </div>
              <div className="label-and-input-pair">
                <label>Website:</label>
                <input
                  type="text"
                  value={companyData.website}
                  onChange={(e) =>
                    setCompanyData({ ...companyData, website: e.target.value })
                  }
                />
              </div>
              <button type="submit" disabled={submitting}>
                {submitting ? "Editing..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsCompanyInfoCard;
